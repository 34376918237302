import React from "react";
import { GoInfo } from "react-icons/go";
import { Tooltip } from "flowbite-react";
import { SpecilnationmarkSVG } from "src/theme/Images";
import { useAppSelector } from "../../../redux/hooks";
import { MinusCircle, PlusCircle } from "@untitled-ui/icons-react/build/cjs";

const ProductCard = ({ product, onClick, handleAction, isSelected = false }: any) => {
    const uiState = useAppSelector((data) => data.UiStates);

    return (
        <div className="w-full flex flex-col">
            <div className={`border p-[10px] rounded-secondary h-full bg-productBackgroundColor ${isSelected ? "border-primary" : "border-borderColorPrimary hover:border-primary"}`}>
                <div className="cursor-pointer" onClick={handleAction("product", product)}>
                    <img
                        className="w-full rounded-secondary h-[118px] sm:h-[106px] md:h-[139px] lg:h-[80px] xl:h-[110px] 2xl:h-[138px] 3xl:h-[200px]  object-cover"
                        src={product?.images?.find((image: any) => image?.is_main)?.image_url}
                        alt=""
                    />
                </div>
                <div className="flex items-end justify-between mt-3">
                    <div>
                        <h2 className="  text-sm font-bold text-txtcolor leading-[140%] tracking-[0.03rem] mb-[8px]">{product.name}</h2>
                        <div className="grid grid-cols-2 md:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-3 items-center gap-3 mt-1.5 tracking-[0.03rem]">
                            {product.selectedVariant ? (
                                <div className="variant  cursor-pointer active_add relative" onClick={onClick(product, product.selectedVariant)}>
                                    <p className="variant_text_title">
                                        {product.selectedVariant.size} {product.unit_of_measure}
                                    </p>
                                    <span className="variant_border  active_add"></span>
                                    <p className="variant_text_price">${product.selectedVariant.inventory.price}</p>
                                    <MinusCircle className="w-4 absolute h-4 top-[-8px] right-[-8px] bg-contentBackground rounded-full" />
                                </div>
                            ) : (
                                product.variants.map((variant: any) => (
                                    <div className="variant group hover:border-primary cursor-pointer relative" key={variant.id} onClick={onClick(product, variant)}>
                                        <p className="variant_text_title">
                                            {variant.size} {product.unit_of_measure}
                                        </p>
                                        <span className="variant_border group-hover:border-primary"></span>
                                        <p className="variant_text_price">${variant.inventory.price}</p>
                                        <PlusCircle className="w-4 absolute h-4 top-[-8px] right-[-8px] bg-contentBackground rounded-full group-hover:border-primary" />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    {uiState.isMobile ? (
                        <div className="cursor-pointer items-end" onClick={handleAction("product", product)}>
                            <SpecilnationmarkSVG color="#8C9CBB" />
                        </div>
                    ) : (
                        <Tooltip
                            content={
                                <div>
                                    <p className="text-txtcolor tracking-[0.03rem]">{product.name}</p>
                                    <p className="text-secondaryTxt font-medium text-xs tracking-[0.03rem]">{product.description}</p>
                                </div>
                            }
                            trigger="hover"
                            style={"light" as any}
                            className="w-64 bg-contentBackground !rounded-secondary tracking-[0.03rem]"
                            placement="bottom"
                        >
                            <GoInfo id="alert_circle_svg" size={16} className="text-primary" onClick={handleAction("product", product)} />
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
