import React, { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import DateTimePick from "./DateTimePick";
import { format, isToday } from "date-fns";
import ButtonCmp from "../../../components/ButtonCmp";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setStep } from "../Booking.slice";
import { EBookingTabState } from "../bookAppointment.interface";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { ArrowLeft } from "@untitled-ui/icons-react/build/cjs";
interface IProps {
    closeCurrentTab: () => void;
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    selectedTimeSlot: string | undefined;
    setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SelectDateAndTime = () => {
    const { t } = useTranslation();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isTimeSlotLoading, setIsTimeSlotLoading] = useState(false);
    const [isShopWorkingHoursLoading, setIsShopWorkingHoursLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(bookingState.selectedBookingDate ? new Date(bookingState.selectedBookingDate) : new Date());
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string | undefined>(undefined);
    useEffect(() => {
        setSelectedTimeSlot(bookingState.selectedBookingTime ?? "");

        return () => {
            setSelectedTimeSlot(undefined);
        };
    }, []);
    const dispatch = useAppDispatch();
    const handleStep = async (step: EBookingTabState) => {
        await dispatch(setStep(step));
    };
    const handleBack = () => {
        handleStep(EBookingTabState.TAB_MENU);
    };
    return (
        <div className="w-full ">
            <div className="flex justify-between items-center lg:mt-4 mt-5  ">
                <div className="title text-txtcolor font-medium text-2xl leading-[128%] flex gap-3 items-center">
                    <div
                        className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton"
                        onClick={handleBack}
                    >
                        <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                    </div>
                    <span className="location-title-text text-[24px]">{t("Select date and time")}</span>
                </div>

                {!uiState.isMobile && (
                    <div className="flex items-center justify-center gap-4 flex-row">
                        <p className="font-medium text-sm  text-txtcolor xl:text-base leading-[140%] pt-[1px] flex tracking-[0.02rem]">{format(selectedDate, "MMMM Y")}</p>
                        <ButtonCmp
                            className={`h-[28px] w-[66px] border-[1px] border-primary px-4 py-1 rounded-secondary text-xs font-bold ${
                                selectedDate && isToday(selectedDate) ? "text-white bg-primary" : "text-primary"
                            }`}
                            onClick={() => setSelectedDate(new Date())}
                        >
                            <span className="pt-[1px] flex tracking-[0.02rem]"> {t("Today")}</span>
                        </ButtonCmp>
                    </div>
                )}
            </div>
            <div className="w-full md:w-1/2 mt-2 mb-4">
                <BarLoader isLoading={isTimeSlotLoading || isShopWorkingHoursLoading} />
            </div>
            <DateTimePick
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                closeCurrentTab={() => handleStep(EBookingTabState.TAB_MENU)}
                isTimeSlotLoading={isTimeSlotLoading}
                setIsTimeSlotLoading={setIsTimeSlotLoading}
                isShopWorkingHoursLoading={isShopWorkingHoursLoading}
                setIsShopWorkingHoursLoading={setIsShopWorkingHoursLoading}
            />
        </div>
    );
};

export default SelectDateAndTime;
