import React, { FC, useState } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { IOtp, IVerifyOtpProps } from "../Auth.interface";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LOCAL_STORAGE_KEYS, errorCode } from "src/constants/common";
import { userLogin, userPhoneEmailLogin } from "src/redux/reducers/Login.slice";
import { useAppDispatch } from "src/redux/hooks";
import OTPField from "src/components/OTPField/OTPField";
import CustomButton from "src/components/CustomButton";
import { IInitAction } from "../Auth.interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { me } from "src/redux/reducers/common/Common.slice";
import { FaArrowLeftLong } from "react-icons/fa6";

const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, handleVerifyOtpRedirect, handleAuthAction, setAuthData }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handlerLoginOtpAgainSend = async () => {
        reset();
        let cread;
        if (authData.phone && authData.email) {
            cread = {
                email: authData.email,
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
            const result: any = await dispatch(userPhoneEmailLogin(cread));
            if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            }
        } else {
            if (authData.isEmail) {
                cread = {
                    email: authData.email,
                };
            } else {
                cread = {
                    phone: `+${authData.phone.code}${authData.phone.number}`,
                    phone_country_code: authData.phone.country,
                };
            }
            const result: any = await dispatch(userLogin(cread));
            if (result.type === userLogin.fulfilled.toString()) {
            }
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(handleSave)();
        }
    };
    const schema = Yup.object({
        otp: Yup.string().length(4, "otp must be exactly 4 number").required("This field is a required "),
    }).required();
    const { handleSubmit, control, setError, reset, watch } = useForm<IOtp>({
        resolver: yupResolver(schema) as Resolver<IOtp>,
        defaultValues: {
            otp: "",
        },
    });

    const otpWatch = watch("otp");

    const handleSave = async (data: any) => {
        setLoading(true);
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
                otp: data.otp,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp,
            };
        }
        if (authData.email && authData.phone) {
            axiosPost(API.USER.PHONE_EMAIL_VERIFY_OTP, payload)
                .then(async (response: any) => {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                    await dispatch(me());
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        Object.keys(response.data).forEach((field) => {
                            setError("otp", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        // setValue('number', new Array(4).fill(''));
                    }
                })
                .finally(() => setLoading(false));
        } else {
            axiosPost(API.USER.VERIFY_OTP, payload)
                .then(async (result: any) => {
                    const enhancedAuthData = {
                        ...authData,
                        otp: data.otp,
                    };
                    if (result?.data?.data?.is_new_user) {
                        setAuthData(enhancedAuthData);
                        handleVerifyOtpRedirect();
                    } else {
                        if (result?.data?.data?.token && result?.data?.data?.is_password_set === false) {
                            setAuthData(enhancedAuthData);

                            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.data.data.token);
                            await dispatch(me());
                        } else if (result?.data?.data?.token) {
                            setAuthData(enhancedAuthData);

                            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.data.data.token);
                            await dispatch(me());
                        } else {
                            const nameAuthData = {
                                ...enhancedAuthData,
                                name: result?.data?.data?.first_name,
                            };
                            setAuthData(nameAuthData);
                            handleAuthAction("passwordVerification");
                        }
                    }
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        Object.keys(response.data).forEach((field) => {
                            setError("otp", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        // setValue('number', new Array(4).fill(''));
                        // setactiveOTPIndex(0);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleAction = (type: keyof IInitAction) => () => {
        handleAuthAction(type);
    };

    const handleChangePhoneEmail = async () => {
        if (authData.isEmail) {
            setAuthData({
                ...authData,
                email: null,
                isEmail: false,
            });
        } else {
            setAuthData({
                ...authData,
                phone: null,
                isEmail: true,
            });
        }
        handleAuthAction("phoneEmailVerification");
    };

    return (
        <div className="font-primary max-w-[400px] mx-auto lg:w-full" onKeyPress={handleKeyPress}>
            <h2 className="login-title-text ">{t("Enter the verification code")}</h2>
            <div className="login-subtitle-text flex flex-col mt-3 tracking-[0.03rem]">
                <span>{t("A 4-digit code has been sent to you at")}</span>
                <span className="text-txtAppointmentColor font-normal text-base mt-1.5">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
            </div>

            <div className="mx-auto mt-[40px] ">
                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col mt-8">
                                <OTPField otp={value} setOtp={onChange} errors={error} />
                                {/* {error && <p className="text-error text-center">{error.message}</p>} */}
                            </div>
                        )}
                    />
                    {authData.isEmail && (
                        <div className="text-txtAppointmentColor font-normal text-base mt-1.5">
                            <span>{t("Tip: Be sure to check your inbox and spam folders")}</span>
                        </div>
                    )}
                    <div className="mt-6 flex flex-col items-center">
                        <div className="flex items-center">
                            <p className="text-textGrey font-medium text-sm leading-5 tracking-[0.03rem]">{t("Didn’t receive code")}?</p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handlerLoginOtpAgainSend}
                                className="text-primary !border-0 !ml-2 !px-0 !bg-transparent text-center flex flex-col ring-0"
                                shadow={false}
                            >
                                {t("Resend")}
                            </CustomButton>
                        </div>
                        {authData.email && authData.phone && (
                            <CustomButton
                                type="button"
                                className="!border-0 !bg-transparent text-center ring-0 leading-[19.6px] text-sm font-semibold mt-2 text-textGrey"
                                shadow={false}
                                onClick={handleChangePhoneEmail}
                            >
                                {authData.isEmail ? t("Changed your email?") : t("Changed your mobile number?")}
                            </CustomButton>
                        )}
                        <div className="text-center mt-6">
                            <CustomButton type="button" className="!border-0 !bg-transparent text-center ring-0 text-txtAppointmentColor" shadow={false} onClick={handleAction("login")}>
                                <FaArrowLeftLong size={14} className="mr-2" /> {t("Back to log in")}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="mt-[78px] w-full bottom-0">
                        <CustomButton type="submit" className="w-full" primary disabled={loading || (otpWatch && otpWatch.length !== 4 ? true : false)} isLoading={loading}>
                            <span> {t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
