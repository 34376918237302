import React, { useEffect, useMemo, useState } from "react";
import { Pluse } from "../../../../components/SvgColor/svgIcons";
import DateSelectButton from "../../../../components/DateSelectButton";
import ButtonCmp from "../../../../components/ButtonCmp";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import RangeCalnedarComponent from "./RangeCalnedarComponent";
import { format } from "date-fns";
import { axiosPost } from "../../../../utils/requestClient";
import { API } from "../../../../constants/api";
import { toast } from "react-toastify";
import { getNextAvailableDay, getNextDayOfWeek } from "../../../../utils/global-functions";
import { getBookingInfo, setSelectedBookingDate, setTempStore } from "../../Booking.slice";
import { ISpecialist, ISpecialistService, IWorkingHour } from "../../../../Interface";
import "./datepicker_mobile.scss";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { useTranslation } from "react-i18next";
import { BiSun } from "react-icons/bi";
import { BsFillSunsetFill, BsMoonFill } from "react-icons/bs";
import moment from "moment";
import ManageSubmit from "../../AppointmentPreview/ManageSubmit";

interface IProps {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    selectedTimeSlot: string | undefined;
    setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
    closeCurrentTab: () => void;
    isTimeSlotLoading: boolean;
    setIsTimeSlotLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isShopWorkingHoursLoading: boolean;
    setIsShopWorkingHoursLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IPeriods {
    name: "all" | "morning" | "afternoon" | "evening";
    label: string;
    start: string;
    end: string;
    icon?: React.ReactNode;
}
const initPeriods: IPeriods[] = [
    { name: "all", label: "All", start: "00:00", end: "23:59" },
    { name: "morning", label: "Morning", start: "06:00", end: "11:59", icon: <BiSun className="w-4 h-4 mr-1.5" /> },
    { name: "afternoon", label: "Afternoon", start: "12:00", end: "18:59", icon: <BsFillSunsetFill className="w-4 h-4 mr-1.5" /> },
    { name: "evening", label: "Evening", start: "19:00", end: "23:59", icon: <BsMoonFill className="w-3 h-3 mr-1.5" /> },
];
const DateTimePick = ({
    selectedDate,
    setSelectedDate,
    selectedTimeSlot,
    setSelectedTimeSlot,
    isTimeSlotLoading,
    setIsTimeSlotLoading,
    isShopWorkingHoursLoading,
    setIsShopWorkingHoursLoading,
}: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [slots, setSlots] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState<IPeriods>(initPeriods[0]);
    const [workingDays, setWorkingDays] = useState<string[]>([]);
    const seletedDateFirstRender = useFirstRender({ selectedDate });
    const [periods, setPeriods] = useState<IPeriods[]>(initPeriods);

    useEffect(() => {
        if (!bookingState.selectedSpecialist) {
            listStaff();
        }
    }, []);

    useEffect(() => {
        const workingDaysArray = bookingState.selectedSpecialist?.working_hours
            ? bookingState.selectedSpecialist?.working_hours.map((workingHour: IWorkingHour) => (workingHour.status ? workingHour.day : false))
            : [];
        setWorkingDays(workingDaysArray.filter((f) => f) as string[]);
    }, [bookingState.selectedSpecialist]);

    useEffect(() => {
        listSlots();
        // setShowLimitedTimeSlots(uiState.isMobile ? 10 : undefined);
        if (seletedDateFirstRender === false) {
            setSelectedTimeSlot(undefined);
            dispatch(setTempStore({ selectedTimeSlot: undefined }));
        }

        if (format(selectedDate, "yyyy-MM-dd") === bookingState.selectedBookingDate) {
            setSelectedTimeSlot(bookingState.selectedBookingTime ?? "");
            dispatch(setTempStore({ selectedBookingTime: bookingState.selectedBookingTime ?? undefined }));
        }
        dispatch(setTempStore({ selectedBookingDate: selectedDate ? format(selectedDate, "Y-MM-dd") : "un" }));
    }, [selectedDate]);

    useEffect(() => {
        if (slots.length) {
            const activePeriods = new Set();
            slots.forEach((time) => {
                const momentTime = moment(time, "HH:mm");
                initPeriods.forEach(({ name, start, end }) => {
                    if (momentTime.isBetween(moment(start, "HH:mm"), moment(end, "HH:mm"))) {
                        activePeriods.add(name);
                    }
                });
            });
            const filteredPeriods = initPeriods.filter((period) => activePeriods.has(period.name));
            setPeriods(filteredPeriods);
        }
    }, [slots]);

    const filteredSlots = useMemo(
        () => slots.filter((time: moment.MomentInput) => moment(time, "HH:mm").isBetween(moment(selectedPeriod.start, "HH:mm"), moment(selectedPeriod.end, "HH:mm"))),
        [slots, selectedPeriod],
    );

    const handleOnclickTimeSlot = (slot: string | undefined) => {
        // dispatch(setSelectedBookingTime(slot));
        dispatch(setTempStore({ selectedBookingTime: slot }));
        setSelectedTimeSlot(slot);
    };

    const listStaff = () => {
        setIsShopWorkingHoursLoading(true);
        const serviceIds = bookingState.selectedServices?.map((service: ISpecialistService) => service.id);
        const payload = {
            service_id: serviceIds.length ? serviceIds : undefined,
            booking_date: bookingState.selectedBookingDate,
            booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
        };
        const params = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.STAFF.LIST, payload, params)
            .then((response) => {
                const allSpecialists = response.data.data;
                const workingDaysArray: string[] = [];
                allSpecialists.forEach((specialistData: ISpecialist) => {
                    if (specialistData?.working_hours) {
                        specialistData?.working_hours.forEach((workingHour: IWorkingHour) => (workingHour.status ? workingDaysArray.push(workingHour.day) : false));
                    }
                });
                setWorkingDays(workingDaysArray.filter((item, index) => workingDaysArray.indexOf(item) === index));
            })
            .finally(() => {
                setIsShopWorkingHoursLoading(false);
            });
    };

    const listSlots = () => {
        setIsTimeSlotLoading(true);
        const payload = {
            booking_date: format(selectedDate, "yyyy-MM-dd"),
            booking_id: bookingState.modifyingAppointmentId ? bookingState.modifyingAppointmentId : null,
            staff_id: bookingState.selectedSpecialist?.id,
            services: bookingState.selectedServices.length
                ? bookingState.selectedServices.map((service) => {
                      const servicePayloadElm = {
                          id: service.id,
                          quantity: service.quantity,
                      };
                      return servicePayloadElm;
                  })
                : undefined,
        };
        const params = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.BOOKING.SLOTS, payload, params)
            .then((response) => {
                setSlots(response.data.data);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsTimeSlotLoading(false);
            });
    };

    const handleLoadNextWorkingDate = () => {
        const nextAvailableDay = getNextAvailableDay(format(selectedDate, "iiii"), workingDays);
        if (nextAvailableDay) {
            const nextDate = getNextDayOfWeek(selectedDate, nextAvailableDay);
            setSelectedDate(nextDate);
            // dispatch(setSelectedBookingDate(format(nextDate, "Y-MM-dd")));
            dispatch(setTempStore({ selectedBookingDate: format(nextDate, "Y-MM-dd") }));
        }
    };

    return (
        <div className="flex py-1 flex-col w-full h-[calc(100vh-233px)] lg:h-[calc(100vh-196px)]">
            <div className="flex-shrink-0">
                {!isShopWorkingHoursLoading && (
                    <div className="relative py-42 flex justify-between">
                        {/* {uiState.isMobile ? ( */}
                        {/* <DatePicker
                        selected={selectedDate && new Date(selectedDate)}
                        onChange={(changedDate: Date) => {
                            setSelectedDate(new Date(changedDate));
                        }}
                        inline={true}
                        minDate={new Date()}
                        calendarClassName="mobile_view-datepicker"
                        dayClassName={(date) =>
                            isOnAnyDay(date, workingDays) ? "dot_show" : ""
                        }
                        calendarStartDay={1}
                        showMonthDropdown={true}
                        disabledKeyboardNavigation={true}
                    >
                        <button
                            className="absolute top-0 text-txtcolor text-sm font-bold right-3"
                            onClick={() => setSelectedDate(new Date())}
                        >
                            {t("Today")}
                        </button>
                    </DatePicker> */}
                        {/* ) : ( */}
                        <RangeCalnedarComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} workingDays={workingDays} />
                        <div className="flex flex-col gap-[1px] md:pl-5 pl-4 pt-4 items-center">
                            <DateSelectButton
                                className="flex justify-center items-center md:w-[50px] md:h-[50px] w-[30px] h-[30px]  border-[1px] border-primaryAppointment rounded-primary"
                                dateFormat="dd/MM/yyyy"
                                selectedDate={selectedDate}
                                onChangeFunc={(changedDate: Date) => {
                                    setSelectedDate(new Date(changedDate));
                                    dispatch(setTempStore({ selectedBookingDate: format(new Date(changedDate), "Y-MM-dd") }));

                                    dispatch(setSelectedBookingDate(format(new Date(changedDate), "Y-MM-dd")));
                                }}
                                minDate={new Date()}
                                closeOnOutsideClick={true}
                            >
                                {/* <img src={PLUS} className="md:w-5 w-4" alt="" /> */}
                                <Pluse fillColor="var(--brand-color)" />
                            </DateSelectButton>
                            <p className="text-secondaryTxt md:mt-2 mt-1 text-center">{t("More")}</p>
                        </div>

                        {/* )} */}
                    </div>
                )}
                {/* {!isTimeSlotLoading && !!slots.length && (
                    <>
                        {bookingState.selectedSpecialist ? (
                            <div className="flex gap-4 items-center mt-5">
                                <img src={bookingState.selectedSpecialist.profile_image_url} className="w-12  rounded-2xl" alt="" onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)} />
                                <div>
                                    <h2 className=" leading-5 font-semibold text-sm text-txtcolor">
                                        {`Available times for ${bookingState.selectedSpecialist.first_name} ${bookingState.selectedSpecialist.last_name} on ${format(
                                            selectedDate,
                                            "EEEE, LLLL dd",
                                        )}${getOrdinalSuffix(format(selectedDate, "dd"))}`}
                                    </h2>
                                    <p className="  text-xs text-secondaryTxt">{bookingState.selectedSpecialist.staff_role?.name}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-4 items-center mt-5 md:mt-10">
                                <div className="w-[50px] flex items-center justify-center bg-inputbg border-primary border-2 h-[50px] rounded-2xl text-primary text-2xl">
                                    <HiExclamationCircle />
                                </div>
                                <div className="flex-1">
                                    <h2 className=" leading-5 font-semibold text-sm text-primary">{t("You have not select any stylist yet")}</h2>
                                    <p className="  text-xs text-primary mt-[5px]">{t("Please select stylist according to your selected time slot.")}</p>
                                </div>
                            </div>
                        )}
                    </>
                )} */}
                <div className="fl-tab-btn-view w-full mt-7">
                    {periods.map((period) => (
                        <button
                            key={period.name}
                            type="button"
                            onClick={() => setSelectedPeriod(period)}
                            className={`fl-tab-link  text-txtAppointmentColor border-borderColorPrimary tracking-[0.03rem] ${selectedPeriod.name === period.name ? "active" : ""}`}
                        >
                            {period.icon && period.icon}

                            <span className="mt-0 tracking-[0.04rem]">{period.label}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div className={`flex flex-wrap -mx-[6px] lg:-mx-[10px] pb-4 mt-5 md:mt-10 relative overflow-auto scrollbar-hide`}>
                {!isTimeSlotLoading && (
                    <>
                        {!!filteredSlots.length ? (
                            filteredSlots.map((slot, index) => (
                                <div
                                    className={`px-[6px] lg:px-[10px] w-1/2 md:w-[20%] 2xl:w-[16.6%] mb-[12px] lg:mb-[20px]  ${selectedDate && selectedTimeSlot ? "last:mb-14" : ""}`}
                                    key={`${slot} ${index}`}
                                >
                                    <div
                                        onClick={() => handleOnclickTimeSlot(slot === selectedTimeSlot ? undefined : slot)}
                                        className={` cursor-pointer ${
                                            selectedTimeSlot === slot ? " border border-primary text-primaryAppointment " : "border border-borderColorPrimary text-primaryAppointment "
                                        } font-medium px-2 text-center py-3 transition-all ease-in-out tracking-[0.02rem]  hover:shadow-lg hover:border-primary rounded-secondary text-sm xl:text-base  `}
                                    >
                                        {slot}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex justify-center w-full md:min-h-[50vh] flex-col">
                                <div className="m-auto">
                                    <h1 className="text-txtcolor font-bold  text-base tracking-[0.03rem]">{t("No time is available for selected date.")}</h1>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {/* {!!slots.length && slots.length >= 10 && !isTimeSlotLoading && uiState.isMobile && (
                    <div className="w-full flex justify-center">
                        {showLimitedTimeSlots ? (
                            <ButtonCmp onClick={() => setShowLimitedTimeSlots(undefined)} className="btn_secondary_black mb-8 fl-btn min-w-[240px]">
                                <div className="flex gap-[5px] items-center">
                                    {t("View more times")} <FiArrowRight />
                                </div>
                            </ButtonCmp>
                        ) : (
                            <ButtonCmp onClick={() => setShowLimitedTimeSlots(10)} className="btn_secondary_black mb-8 fl-btn min-w-[240px]">
                                <div className="flex gap-[5px] items-center">
                                    {t("View less times")} <IoIosArrowUp />
                                </div>
                            </ButtonCmp>
                        )}
                    </div>
                )} */}
            </div>
            {!slots.length && !isTimeSlotLoading && (
                <div className="flex justify-center w-full md:min-h-[50vh] flex-col">
                    <div className="m-auto">
                        <h1 className="text-txtcolor font-bold  text-base tracking-[0.03rem]">{t("No time is available for selected date.")}</h1>
                        {bookingState.selectedSpecialist && (
                            <div className="w-full flex items-center justify-center tracking-[0.03rem]">
                                <ButtonCmp className="text-secondaryTxt font-bold tracking-[0.03rem]" onClick={handleLoadNextWorkingDate}>
                                    {t("Select next available date")}
                                </ButtonCmp>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {uiState.isMobile && <ManageSubmit />}
        </div>
    );
};

export default DateTimePick;
