import React, { useEffect, useState } from "react";
import DropdownModal from "src/components/DropdownModal";
import { IInitAction, initAction, initData } from "../Auth.interface";
import SocialProfile from "./SocialProfile";
import VerifyOtp from "./VerifyOtp";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import RegularProfile from "./RegularProfile";

interface IProps {
    handleClose: () => void;
}

const CompleteProfile = ({ handleClose }: IProps) => {
    const user = useAppSelector(currentUser);
    const [action, setAction] = useState(initAction);
    const [authData, setAuthData] = useState(initData);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                const socialId = user.social_id;
                if (socialId) {
                    handleAction("socialProfile")();
                } else {
                    handleAction("regularProfile")();
                }
            }
        }
    }, [user]);

    const handleAction = (type: keyof IInitAction) => () => {
        setAction(() => {
            const resetActions = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IInitAction] = false;
                return acc;
            }, {} as IInitAction);
            return { ...resetActions, [type]: true };
        });
    };

    return (
        <DropdownModal handleClose={handleClose} size="max-w-md">
            <div className="py-8 px-6">
                {action.regularProfile ? (
                    <RegularProfile handleClose={handleClose} />
                ) : action.socialProfile ? (
                    <SocialProfile setAuthData={setAuthData} handleAction={handleAction} />
                ) : (
                    action.otp && <VerifyOtp authData={authData} setAuthData={setAuthData} handleAction={handleAction} handleClose={handleClose} />
                )}
            </div>
        </DropdownModal>
    );
};

export default CompleteProfile;
