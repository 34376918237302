import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { DefaultImageJPG } from "src/theme/Images";
import { usePhone } from "src/hooks/usePhone";

interface Props {
    data: any;
    homeSettings: any;
}
const Location: React.FC<Props> = ({ data, homeSettings }) => {
    const [currentSliderInfo, setCurrentSliderInfo] = useState<any>(null);
    const { getCustomFormatPhone } = usePhone();

    const handleSlideChange = (event: any) => {
        setCurrentSliderInfo(data[event?.realIndex]);
    };
    useEffect(() => {
        if (data.length > 0) {
            setCurrentSliderInfo(data[0]);
        }
    }, []);
    return (
        <>
            {currentSliderInfo ? (
                <div className="w-full mx-auto pt-14 md:pt-36 text-txtcolor">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        loop={data?.length >= 4}
                        navigation={true}
                        className="mySwiper w-full"
                        onSlideChange={handleSlideChange}
                        breakpoints={{
                            // When window width is <= 768px
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {/* Slides with content */}
                        {data.map((location: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <div className="relative w-full">
                                    <div className="profile-images">
                                        <img
                                            src={`${location?.is_image && location.image_url ? location.image_url : DefaultImageJPG}`}
                                            alt="location"
                                            className="w-full h-[480px] sm:h-[410px] md:h-[420px] lg:h-[380px] xl:h-[410px] 2xl:h-[415px] 3xl:h-[550px] profile-img object-cover cursor-pointer"
                                        />
                                    </div>
                                    <div className="w-[-webkit-fill-available] relative border-t mt-[8px] pt-[12px] lg:mt-[0.8vw] lg:pt-[0.8vw] text-txtcolor border-borderColorPrimary">
                                        <div className="flex flex-row md:flex-row justify-between items-start md:items-start">
                                            <span className="text-[14px] uppercase lg:text-[0.8vw] xl:text-[0.9vw] font-normal flex-1 ">{location.title}</span>
                                            <span className="text-[14px] uppercase lg:text-[0.8vw] xl:text-[0.9vw] font-normal">{getCustomFormatPhone(location.contact, location?.country_code)}</span>
                                        </div>
                                        <p className="text-[16px] lg:text-[1.2vw] uppercase font-normal">Open: Monday to Friday (9am - 6pm)</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Location;
