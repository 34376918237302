import { IInitAction } from "../Auth.interface";

export interface IPasswordFiled {
    password: string;
}

export interface IPasswordVerificationProps {
    authData: any;
    resetAuthAction: () => void;
    onCloseModal: () => void;
    handleAuthAction: (type: keyof IInitAction) => void;
}

export interface IForgotPassword {
    emailPhone: any;
}

export interface IForgotPasswordProps {
    handleAuthAction: (type: keyof IInitAction) => void;
    setAuthData: any;
    authData: any;
}

export interface IAuthData {
    isEmail: boolean;
    email: any;
    phone: any;
}

export const AuthDataInit: IAuthData = {
    isEmail: true,
    email: null,
    phone: null,
};

export interface IVerifyOtpProps {
    authData: any;
    resetAuthAction: () => void;
    onCloseModal: () => void;
    handleAuthAction: (type: keyof IInitAction) => void;
    setAuthData: any;
}

export interface IOtp {
    otp?: string;
}

export interface IPasswordField {
    old_password?: string;
    password?: string;
    password_confirmation: string;
}

export type IFieldKey = "password" | "password_confirmation";

export interface IChangePasswordProps {
    forgotPasswordStep?: string;
    authData?: any;
    handleAuthAction: (type: keyof IInitAction) => void;
}
