import React, { FC, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, Resolver, useForm } from "react-hook-form";
import InputPasswordWithLabel from "src/components/InputPasswordWithLabel";
import { useTranslation } from "react-i18next";
import { IChangePasswordProps, IFieldKey, IPasswordField } from "./Password.interface";
import { errorCode } from "src/constants/common";
import CustomButton from "src/components/CustomButton";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";

const NewPassword: FC<IChangePasswordProps> = ({ forgotPasswordStep = true, authData, handleAuthAction }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const schema = Yup.object({
        old_password: !forgotPasswordStep ? Yup.string().required(t("This field is required")) : Yup.string(),
        password: !forgotPasswordStep ? Yup.string().required(t("This field is required")) : Yup.string(),
        password_confirmation: Yup.string()
            .required(t("This field is required"))
            .oneOf([Yup.ref("password")], t("Passwords do not match")),
    }).required();

    const { handleSubmit, control, setError, reset } = useForm<IPasswordField>({
        resolver: yupResolver(schema) as Resolver<IPasswordField>,
        defaultValues: {
            old_password: "",
            password: "",
            password_confirmation: "",
        },
    });

    const [validations, setValidations] = useState({
        minLength: false,
        hasDigit: false,
        hasNonDigit: false,
    });

    const handleValidation = (value: string) => {
        setValidations({
            minLength: value.length >= 8,
            hasDigit: /\d/.test(value),
            hasNonDigit: /\D/.test(value),
        });
    };
    const validationsPassed = validations.minLength && validations.hasDigit && validations.hasNonDigit;

    const handleSave = (data: IPasswordField) => {
        setLoading(true);
        if (forgotPasswordStep) {
            const payload = {
                ...(authData.isEmail ? { email: authData.email } : { phone: `+${authData.phone.code}${authData.phone.number}` }),
                password: data.password,
                password_confirmation: data.password_confirmation,
                token: authData.token,
            };
            axiosPost(API.USER.NEW_PASSWORD, payload)
                .then(() => {
                    reset();
                    handleAuthAction("login");
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as IFieldKey, {
                                    type: "manual",
                                    message: response.data[field][0],
                                });
                            });
                            return;
                        }
                    }
                    toast.error(response.message);
                })
                .finally(() => setLoading(false));
        } else {
            axiosPost(API.USER.CHANGE_PASSWORD, data)
                .then(() => {
                    reset();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as IFieldKey, {
                                    type: "manual",
                                    message: response.data[field][0],
                                });
                            });
                            return;
                        }
                    }
                    toast.error(response.message);
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <div className="content font-primary">
            <h5 className="login-title-text">{t("Change password")}</h5>
            <p className="login-subtitle-text ">{t("Your password must be at least 8 characters long, and contain at least one digit and one non-digit character")}</p>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="flex flex-wrap">
                    {!forgotPasswordStep && (
                        <Controller
                            name="old_password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="flex flex-col w-full">
                                    <InputPasswordWithLabel name="old_password" label={t("Current password")} placeholder={t("Current password")} value={value} onChange={onChange} error={!!error} />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    )}
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mt-4">
                                <InputPasswordWithLabel
                                    name="password"
                                    label={t("New password")}
                                    placeholder={forgotPasswordStep ? t("Password") : t("New password")}
                                    value={value}
                                    onChange={(e: { target: { value: string } }) => {
                                        onChange(e);
                                        handleValidation(e.target.value);
                                    }}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="password_confirmation"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mt-5">
                                <InputPasswordWithLabel
                                    name="password_confirmation"
                                    label={t("Confirm password")}
                                    placeholder={t("Confirm password")}
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="mt-[127px]">
                    <CustomButton className={`w-full mt-5`} primary isLoading={loading} disabled={loading || validationsPassed === true ? false : true} type="submit">
                        {t("Save changes")}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default NewPassword;
