import React, { useEffect, useState } from "react";
import { UnionSVG } from "src/theme/Images";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ISelectedTab } from "../../../../Interface";
import { selectTab, setIsScrollable } from "../../../../redux/reducers/Landing.slice";
import { currentSettings, currentShop } from "src/redux/reducers/common/Common.slice";

const navLinks: { name: string; tabName: ISelectedTab }[] = [
    { name: "LOCATION", tabName: "location" },
    { name: "ABOUT US", tabName: "about" },
    { name: "TEAM", tabName: "team" },
    { name: "SERVICES", tabName: "service" },
    { name: "GALLERY", tabName: "gallery" },
    { name: "ACADEMY", tabName: "academy" },
    { name: "PRODUCT", tabName: "product" },
    { name: "TESTIMONIALS", tabName: "testimonial" },
];
interface Props {
    data: any;
}
const Footer: React.FC<Props> = ({ data }) => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const settings = useAppSelector(currentSettings);
    const activeTab = useAppSelector((tab) => tab.LandingStates.initSelectedTab);
    const [appearance, setAppearance] = useState<any>(null);

    useEffect(() => {
        if (settings.length) {
            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);

    const handleNavigate = (tabName: ISelectedTab) => () => {
        dispatch(setIsScrollable(true));
        dispatch(selectTab(tabName));
    };

    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            logo = <img src={shop.logo_image_url ?? UnionSVG} className="max-w-[120px] h-[46px] lg:h-[50px] me-2 object-contain" alt="logo" />;
            text = (
                <p className="text-txtcolor hover:text-primary cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] flex items-center font-logo font-logoWeight">
                    {shop.business_name}
                </p>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <>
                                {logo} {text}
                            </>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        break;
                }
            }
        }

        return response;
    };

    return (
        <footer className="bg-contentBackground spacing-top text-txtcolor">
            <div className="w-full">
                <div className="flex flex-wrap justify-between pb-14 px-5 md:px-[34px]">
                    <div className="mb-4 lg:mb-0 w-1/3">
                        <div className="flex items-center">{getLogo()}</div>
                    </div>
                    <div className="flex flex-col">
                        <span className="footer-title">LINK</span>
                        {navLinks.map(({ name, tabName }) => (
                            <span
                                key={tabName}
                                className={`footer-sub-title leading-[1.1] mb-1 cursor-pointer ${activeTab[tabName] ? "text-primary" : "text-txtcolor"} `}
                                onClick={handleNavigate(tabName)}
                            >
                                {name}
                            </span>
                        ))}
                    </div>
                    <div className="flex flex-col ">
                        <span className="footer-title">CONTACT</span>
                        <address className="not-italic footer-sub-title flex">{`${data?.address_1} ${data?.address_2} ${data?.city} ${data?.country}, ${data?.zip_code} `}</address>
                        <a href={`mailto:${data?.email}`} target="_blank" className="mt-3 footer-sub-title uppercase flex">
                            {data?.email}
                        </a>
                        <p className="footer-sub-title uppercase flex">Contact for appointment</p>
                    </div>
                    <div className="flex flex-col space-y-2 ">
                        <span className="footer-title">OPEN</span>
                        <div>
                            <span className="footer-sub-title uppercase flex">
                                {data?.start_day} TO {data?.end_day}
                            </span>
                            <span className="text-[14px] lg:text-[.9vw] pt-0.5 text-txtcolor flex">
                                {" "}
                                {data?.start_time}- {data?.end_time}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between border-t items-center pt-5 pb-6 border-borderColorPrimary  mx-5 md:mx-[34px]">
                    <div className="flex w-full flex-col md:flex-row md:w-1/2 md:md:gap-12">
                        <span className="text-[14px] lg:text-[1vw]  text-txtcolor font-normal uppercase ">
                            WE ARE OPEN {data?.start_day} TO {data?.end_day}
                        </span>
                        <span className="text-[14px] lg:text-[1vw]  text-secondaryTxt font-normal uppercase">
                            {data?.special_day} {data?.special_start_time}- {data?.special_end_time}
                        </span>
                    </div>
                    <div className="flex w-full justify-between md:justify-end flex-row mt-3 md:mt-0 md:w-1/2 md:gap-36">
                        <button className="text-[14px] lg:text-[1vw] text-txtcolor font-normal uppercase">Instagram</button>
                        <button onClick={handleNavigate(navLinks[0].tabName)} className="text-[14px] lg:text-[1vw] cursor-pointer text-txtcolor font-normal  uppercase">
                            Back to top
                        </button>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
