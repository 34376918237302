import React, { useEffect } from "react";
import { IShopLocation } from "../../../Interface";
import Booking from "../Booking";
import MapAdvance from "../../../components/MapAdvance";
import { useAppSelector } from "../../../redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

interface IProps {
    shopLocationData: IShopLocation[];
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
    showAppointmentShopOnMap: IShopLocation | undefined;
    setShowAppointmentShopOnMap: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
}

const BarberSearchMapView = ({ shopLocationData, selectedShopData, setSelectedShopData, showAppointmentShopOnMap, setShowAppointmentShopOnMap }: IProps) => {
    const user = useAppSelector(currentUser);

    useEffect(() => {
        if (selectedShopData?.id !== showAppointmentShopOnMap?.id) {
            setShowAppointmentShopOnMap(undefined);
        }
    }, [selectedShopData]);

    return (
        <div className="flex-1  lg:block relative">
            {user && <Booking />}
            <MapAdvance
                shopLocationData={shopLocationData}
                selectedShopData={selectedShopData}
                showAppointmentShopOnMap={showAppointmentShopOnMap}
                setSelectedShopData={setSelectedShopData}
                setShowAppointmentShopOnMap={setShowAppointmentShopOnMap}
            />
        </div>
    );
};

export default BarberSearchMapView;
