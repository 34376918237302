import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentElement from "./PaymentMethod.Element";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

interface IProps {
    closeAddNewCardTab: () => void;
}

const AddNewCardTabComponent = ({ closeAddNewCardTab }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col font-primary relative  h-[calc(100%-20px)] overflow-hidden w-full tracking-[0.03rem]">
                <div className="flex flex-col mb-5 ">
                    <h3 className="location-title-text">{t("Add card on file")}</h3>
                    <span className="location-sub-text">{t("Add a card on file for seamless checkouts")}</span>
                </div>
                <Elements stripe={stripePromise}>
                    <PaymentElement handleClose={closeAddNewCardTab} successBtnText={t("Add Card")} />
                </Elements>
            </div>
        </>
    );
};
export default AddNewCardTabComponent;
