import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { INSTAGRAM_URL } from "../../../../constants/common";
interface Props {
    data: any;
}
const Hero: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div className="w-full mx-auto pt-14 md:pt-36 text-txtcolor">
            <div className="flex">
                <span className="font-semithin uppercase text-[8.9vw] lg:text-[7vw] mb-2 lg:mb-0 leading-[0.8]">
                    {/* {currentSliderInfo.name}{" Montreal Barbershop│ FLAIR Studios"} */}
                    {data.is_title && data.title}
                </span>
            </div>
            <div className="flex flex-col md:flex-row w-full ">
                <div className="flex-1 flex-col">
                    {data?.is_subtitle && (
                        <div className="text-txtcolor font-normal uppercase text-[14px]  leading-[1.1] lg:text-[1vw] mb-5 flex w-full md:w-[70%] lg:w-[60%] xl:w-[34%]">
                            <span>{data?.subtitle}</span>
                        </div>
                    )}

                    <div className="flex">
                        <div className="w-full">
                            <div className="flex flex-col md:flex-row md:justify-between">
                                <div>
                                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-3 ">
                                        {data?.is_button && (
                                            <button className="bg-primary text-primaryText px-8 py-3 h-[42px]" onClick={() => navigate(ROUTES.LOCATION)}>
                                                {data?.button}
                                            </button>
                                        )}

                                        {data?.is_instgram_button && (
                                            <a
                                                className="border border-txtcolor text-txtcolor font-normal px-8 py-3 h-[42px] text-center"
                                                target="_blank"
                                                href={`${INSTAGRAM_URL}/${data?.instgram_button}`}
                                            >
                                                FOLLOW US ON INSTAGRAM
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
