import React, { memo, FC } from "react";
import { IProps } from "./PopupModal.interface";
import { Modal } from "flowbite-react";
import CustomButton from "../CustomButton";

const PopupModal: FC<IProps> = ({
    children,
    size = "md",
    dismissible = false,
    position = "center",
    onClose,
    title = "",
    className = "",
    primaryButton = "",
    secondaryButton = "",
    acceptAction = () => {},
    declineAction = () => {},
}) => (
    <Modal
        show
        popup
        dismissible={dismissible}
        size={size}
        position={position}
        className="modal_backdrop"
        theme={{ content: { inner: `bg-cardBackground rounded-xl shadow`, base: `${size}` } }}
        onClose={onClose}
    >
        {title ? <Modal.Header className="border-b px-5 py-4">{title}</Modal.Header> : <Modal.Header />}
        <Modal.Body>{children}</Modal.Body>
        {primaryButton || secondaryButton ? (
            <Modal.Footer className="border-t px-5 py-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-1/2" secondary onClick={declineAction}>
                        {secondaryButton}
                    </CustomButton>
                    <CustomButton className="w-1/2" primary onClick={acceptAction}>
                        {primaryButton}
                    </CustomButton>
                </div>
            </Modal.Footer>
        ) : (
            ""
        )}
    </Modal>
);

export default memo(PopupModal);
