import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { LuCalendar } from "react-icons/lu";
import { API } from "src/constants/api";
import { axiosGet } from "src/utils/requestClient";
import { Category } from "./Profile.interface";
import { pickBy, some } from "lodash";

const Notification = () => {
    const [notifications, setNotifications] = useState([]);
    const [isNotification, setIsNotification] = useState(false);
    const [filteredNotifications, setFilteredNotifications] = useState({
        [Category.TODAY]: [],
        [Category.YESTERDAY]: [],
        [Category.THIS_WEEK]: [],
        [Category.OLDER]: [],
    });

    useEffect(() => {
        listNotification();
    }, []);

    useEffect(() => {
        const data: any = {
            [Category.TODAY]: [],
            [Category.YESTERDAY]: [],
            [Category.THIS_WEEK]: [],
            [Category.OLDER]: [],
        };

        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");
        const startOfWeek = moment().startOf("week");

        notifications.forEach((item: any) => {
            const date = moment(item.created_at);
            if (date.isSame(today, "d")) {
                data[Category.TODAY].push(item);
            } else if (date.isSame(yesterday, "d")) {
                data[Category.YESTERDAY].push(item);
            } else if (date.isAfter(startOfWeek)) {
                data[Category.THIS_WEEK].push(item);
            } else {
                data[Category.OLDER].push(item);
            }
        });

        const filteredData: any = pickBy(data, (item) => item.length > 0);

        setFilteredNotifications(filteredData);
        setIsNotification(some(filteredData, (arr) => Array.isArray(arr) && arr.length > 0));
    }, [notifications]);

    const listNotification = () => {
        axiosGet(API.NOTIFICATION.LIST).then((response) => {
            setNotifications(response.data.data.data);
        });
    };

    const NotificationCard = ({ notification }: any) => (
        <div className="flex items-start">
            <div>
                <div className={`w-[46px] h-[46px] rounded-primary justify-center flex items-center md:rounded-xl mr-4  bg-cardBackground`}>
                    <LuCalendar size={20} className="text-primary" />
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-medium text-txtcolor">{notification.name}</p>
                <p className="text-sm text-txtcolor">{notification.description}</p>
                <span className="text-sm text-textGrey font-normal">{moment(notification.created_at).fromNow()}</span>
            </div>
            <div>
                <div className="w-[10px] h-[10px] bg-primary rounded-full"></div>
            </div>
        </div>
    );

    const handleMarkAllAsRead = () => {
        //
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-medium text-txtcolor">Notifications</h2>
                <button className="text-primary font-medium text-sm" onClick={handleMarkAllAsRead}>
                    <BiCheckDouble className="inline w-[18px] h-[18px] mr-1" />
                    Mark all as read
                </button>
            </div>
            <div className="flex flex-col gap-5 mt-5">
                {isNotification
                    ? Object.entries(filteredNotifications).map(([category, data]) => (
                          <Fragment key={category}>
                              <p className="text-sm font-medium text-txtcolor capitalize">{category.replace(/_/g, " ")}</p>
                              {data.map((notification: any) => (
                                  <NotificationCard key={notification.id} notification={notification} />
                              ))}
                          </Fragment>
                      ))
                    : "Notifications not available"}
            </div>
        </div>
    );
};

export default Notification;
