import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { Spinner } from "flowbite-react";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";

interface IProps {
    isLoading?: boolean;
}

const AppLayout = ({ isLoading }: IProps) => {
    const shop = useAppSelector(currentShop);

    return (
        <>
            {shop && <Header />}
            <div className="app_layout">
                {isLoading ? (
                    <div className="h-full w-full flex justify-center items-center">
                        <Spinner size="xl" />
                    </div>
                ) : (
                    <Outlet />
                )}
            </div>
            {/* {shop && (
                <div className="hidden lg:block">
                    <Footer />
                </div>
            )} */}
        </>
    );
};

export default AppLayout;
