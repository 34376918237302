import React, { useState } from "react";
interface Props {
    data: any;
}
const Product: React.FC<Props> = ({ data }) => {
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: false });

    type ProductProps = {
        info: any;
        index: React.Key | null | undefined;
    };

    const Products: React.FC<ProductProps> = ({ info, index }) => (
        <div
            className="border-t relative service-duration py-[5.2vw] md:py-[3vw] lg:py-[2vw] xl:py-[1.2vw] border-b-animation border-borderColorPrimary text-txtcolor transition-all cursor-pointer"
            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
        >
            <div className="flex justify-between items-center">
                <div className="flex w-1/2">
                    <h3 className="text-[14px] leading-[1] pt-2.5 lg:text-[1vw] font-normal uppercase text-txtcolor">{info.name}</h3>
                </div>
                <div className="flex w-1/2 md:pl-5 xl:pl-0 justify-between">
                    <div className="flex-1 hidden md:flex">
                        <h3 className="text-[14px] lg:text-[1vw] leading-[1] pt-2.5 font-normal uppercase text-txtcolor">${info.price} USD</h3>
                    </div>
                    <div className="w-full lg:w-auto text-right">
                        <button
                            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                            className="text-[14px] lg:text-[1vw] font-normal leading-[1] pt-2.5 uppercase text-txtcolor min-w-8"
                        >
                            {isCollapsed.isShow && isCollapsed.id === index ? "LESS −" : "MORE +"}
                        </button>
                    </div>
                </div>
            </div>
            {isCollapsed.isShow && isCollapsed.id === index && (
                <div className="mt-4 service-duration">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="w-full lg:w-1/2">
                            <div className="flex flex-col md:flex-row md:space-x-2 w-full">
                                {info.urls && info.urls[0] && (
                                    <div className="flex-none">
                                        <img src={`${info.urls[0]}`} alt="Large product" className=" w-full md:w-[264px] h-[264px] object-cover" />
                                    </div>
                                )}
                                {info.urls && info.urls.length > 1 && (
                                    <div className="flex flex-row flex-wrap gap-2 w-full">
                                        {info.urls.slice(1).map((imgUrl: string, urlIndex: React.Key | null | undefined) => (
                                            <img key={urlIndex} src={imgUrl} className="w-[50%] md:w-32 h-32 object-cover" alt={info.name} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <h3 className="md:pl-5 xl:pl-0 pt-3 xl:pt-0 text-[16px] lg:text-[1.4vw] text-txtcolor mb-3 font-medium uppercase">{info.name}</h3>
                            <p className="md:pl-5 xl:pl-0 mb-3 font-light text-[14px] md:text-[16px] xl:text-[1.111111vw] ">{info.description}</p>
                            <div className="flex-1 mb-4 md:hidden">
                                <h3 className="text-[14px] md:pl-5 xl:pl-0 lg:text-[1vw] font-normal uppercase text-txtcolor">${info.price} USD</h3>
                            </div>
                            <button className="bg-primary text-primaryText md:ml-5 xl:ml-0 px-8 py-3 h-[42px]">{info?.button}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <section>
            <div className="spacing-top">
                <h2 className="custom-heading font-semithin uppercase text-txtcolor">Our Product</h2>
                <div className="mt-8 border-b border-borderColorPrimary relative">
                    {data?.products?.map((product: any, index: React.Key | null | undefined) => (
                        <Products key={index} info={product} index={index} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Product;
