import React, { FC } from "react";
import { IOTPField } from "./OTPField.interface";
import { InputOtp } from "primereact/inputotp";

const OTPField: FC<IOTPField> = ({ otp, setOtp, errors }): JSX.Element => (
    <InputOtp
        value={otp}
        onChange={(e) => setOtp(e.value)}
        integerOnly
        className=""
        unstyled
        pt={{
            root: { className: "flex justify-between items-center gap-3" },
            input: {
                className: `flex pt-[23px] items-center justify-center w-[74px] h-[74px] sm:w-[80px] sm:h-[80px] border !border-borderColorSecondary rounded-secondary bg-transparent transition p-4 text-4xl text-txtAppointmentColor font-medium text-center focus:outline-0 focus:ring-2 shadow-InputAndButton ${
                    errors ? "is-invalid border border-red-400" : "focus:ring-borderColorSecondary bg-transparent "
                }`,
            },
        }}
    />
);

export default OTPField;
