import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setNotificationToken } from "../../redux/reducers/Login.slice";
import { errorCode } from "../../constants/common";
import { toast } from "react-toastify";
import { onMessageListener, requestForFirebaseToken } from "../../services/API/firebase";
import { axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import Template1Header from "../Landing/Template1/Header";
import Template2Header from "../Landing/Template2/Header";
import CommonHeader from "./CommonHeader";
import { currentUser } from "src/redux/reducers/common/Common.slice";

const Header = () => {
    const dispatch = useAppDispatch();
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const template = useAppSelector((data) => data.Common.template);

    useEffect(() => {
        const getOrUpdateFirebaseNotificationToken = async () => {
            let FCM_TOKEN = await requestForFirebaseToken();
            if (!FCM_TOKEN) {
                FCM_TOKEN = await requestForFirebaseToken();
            }

            if (FCM_TOKEN && FCM_TOKEN !== AuthUserState.notificationToken && user) {
                dispatch(setNotificationToken(FCM_TOKEN));

                try {
                    const response = await axiosPost(API.NOTIFICATION.CREATE_UPDATE, {
                        token: FCM_TOKEN,
                    });
                    if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                        return;
                    }

                    throw response.data;
                } catch (err: any) {
                    toast.error(err?.message);
                }
            }
        };

        getOrUpdateFirebaseNotificationToken();

        if (user) {
            onMessageListener()
                .then((payload: any) => {
                    if (payload?.notification?.title !== "Appointment Requested") {
                        toast.success(payload?.notification?.body);
                    }
                })
                .catch((err) => {});
        }
    }, [user, AuthUserState.notificationToken, dispatch]);

    const renderHeader = () => {
        switch (template) {
            case "template_1":
                return <Template1Header />;
            case "template_2":
                return <Template2Header />;
            default:
                return <CommonHeader />;
        }
    };

    return <>{renderHeader()}</>;
};

export default Header;
