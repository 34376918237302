import React from "react";
import { Step1PNG } from "src/theme/Images";
const Step1 = () => (
    <>
        <p className="text-[16px] font-normal text-txtAppointmentColor">
            Open <span className="text-primary">www.studios.getflair.ca </span>on your browser
        </p>
        <div className="mt-2 bg-white rounded-secondary">
            <img src={Step1PNG} className="w-full rounded-secondary" alt="step1" />
        </div>
    </>
);

export default Step1;
