import React from "react";
import { Step5PNG } from "src/theme/Images";

const Step5 = () => (
    <>
        <p className="text-[16px] font-normal text-txtAppointmentColor">Enable push notifications.</p>
        <div className="mt-2 bg-white rounded-secondary">
            <img src={Step5PNG} className="w-full rounded-secondary" alt="step5" />
        </div>
    </>
);

export default Step5;
