import React, { ReactElement } from "react";

export interface IPops {
    disabled?: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, index?: number) => void;
    value: string;
    name: string;
    placeholder?: string;
    label?: string;
    type?: string;
    index?: number;
    handlerIconClick?: () => void;
    buttonIcon?: ReactElement | null;
    buttonIconRight?: ReactElement | null;
    inputClass?: string;
    min?: number;
    borderClass?: string;
}

const InputWithLabel: React.FC<IPops> = ({
    disabled = false,
    handleChange,
    value,
    name,
    placeholder,
    label,
    type,
    index,
    handlerIconClick,
    buttonIcon,
    borderClass,
    inputClass,
    buttonIconRight,
    min,
}) => (
    <div className="form_group w-full">
        {label && <label className="text-txtAppointmentColor text-sm font-normal leading-5 tracking-[0.03rem] mb-4 ">{label}</label>}
        <div className={`fl-input-field fl-phone-field !h-10 rounded-secondary border border-borderColorSecondary form_input_control shadow-InputAndButton relative ${borderClass}`}>
            <input
                className={`rounded-secondary w-full py-0 !border-0 text-base focus:ring-0 !focus:outline-none outline-none font-normal pt-[1px] placeholder:text-textGrey placeholder:text-base bg-transparent placeholder:font-normal text-txtAppointmentColor tracking-[0.03rem] placeholder:tracking-[0.03rem] h-[-webkit-fill-available] ${inputClass}`}
                type={type || "text"}
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                placeholder={placeholder}
                disabled={disabled}
                min={min}
            />
            {buttonIcon && (
                <span onClick={handlerIconClick ?? undefined} className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-4">
                    {buttonIcon}
                </span>
            )}
            {buttonIconRight && (
                <span onClick={handlerIconClick ?? undefined} className="text-secondaryTxt  cursor-pointer">
                    {buttonIconRight}
                </span>
            )}
        </div>
    </div>
);

export default InputWithLabel;
