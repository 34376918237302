import React, { FC, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import InputPasswordWithLabel from "src/components/InputPasswordWithLabel";
import { useAppDispatch } from "src/redux/hooks";
import { useTranslation } from "react-i18next";
import { IPasswordFiled, IPasswordVerificationProps } from "./Password.interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { LOCAL_STORAGE_KEYS, errorCode } from "src/constants/common";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomButton from "src/components/CustomButton";
import { IInitAction } from "../Auth.interface";
import { me } from "src/redux/reducers/common/Common.slice";
import { toast } from "react-toastify";

const VerifyPassword: FC<IPasswordVerificationProps> = ({ authData, resetAuthAction, onCloseModal, handleAuthAction }) => {
    const schema = Yup.object({
        password: Yup.string().required("Password is a required field"),
    }).required();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setIsLoading] = useState(false);
    // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IPasswordFiled>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: "",
        },
    });

    const handleSave = async (data: IPasswordFiled) => {
        setIsLoading(true);
        let payload = {};
        if (authData.isEmail) {
            payload = {
                password: data.password,
                email: authData.email,
                otp: authData.otp,
            };
        } else {
            const mainNumber = `+${authData.phone.code}${authData.phone.number}`;
            payload = {
                password: data.password,
                phone: mainNumber,
                phone_country_code: authData.phone.country,
                otp: authData.otp,
            };
        }
        axiosPost(API.USER.VERIFY_PASSWORD, payload)
            .then(async (response) => {
                localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                await dispatch(me());
                onCloseModal();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IPasswordFiled, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleAction = (type: keyof IInitAction) => () => {
        resetAuthAction();
        handleAuthAction(type);
    };

    return (
        <div className="content font-primary">
            <h1 className="login-title-text ">
                {t("Welcome back")}, {authData.name}
            </h1>
            <div className="mt-5">
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className="flex flex-wrap">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="flex flex-col w-full">
                                    <InputPasswordWithLabel label={t("Password")} placeholder={t("Enter your password")} onChange={onChange} value={value.password} name="password" isFocus={true} />
                                    {errors.password && <p className="text-error">{errors.password && errors.password.message}</p>}
                                </div>
                            )}
                        />
                    </div>

                    <div className="mt-8 text-txtAppointmentColor font-normal text-base  leading-10 text-center">
                        <span className="text-primaryAppointment cursor-pointer tracking-[0.03rem]" onClick={handleAction("forgotPassword")}>
                            {t("forgot your password")}
                        </span>
                    </div>
                    <div className="w-full flex justify-center">
                        <CustomButton
                            type="button"
                            className=" !border-0 text-txtAppointmentColor !bg-transparent !outline-none !focus:ring-0 !focus:outline-none ring-0"
                            shadow={false}
                            onClick={() => resetAuthAction()}
                        >
                            <FaArrowLeftLong size={14} className="mr-2" /> {t("Back to log in ")}
                        </CustomButton>
                    </div>
                    <div className="mt-[161px]">
                        <CustomButton
                            type="submit"
                            isLoading={loading}
                            disabled={loading}
                            primary
                            className={`fl-btn p-3 bg-primary text-white font-bold w-full mt-[20px] rounded-secondary  transition-all ease-in-out delay-75`}
                        >
                            {"Continue"}
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyPassword;
