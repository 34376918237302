import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorType, IInitialState } from "./Common.interface";
import { axiosGet } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { errorCode } from "../../../constants/common";
import { getSlug } from "../../../utils/global-functions";
import { RootState } from "src/redux/store";

const initialState: IInitialState = {
    settings: [],
    products: [],
    shop: null,
    user: null,
    loading: false,
    error: null,
    template: null,
};

export const me = createAsyncThunk("user/me", async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
        const { Common }: any = getState();
        const response = await axiosGet(API.USER.ME, {}, { shop_id: Common.shop.id });
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopSettings = createAsyncThunk("setting/all", async (payload: any, { rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.SETTING.GET, {}, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getShop = createAsyncThunk("user/shop/get", async (payload, { dispatch, rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER_SHOP.GET, {}, { slug: getSlug() });
        if (response.data.status === errorCode.success) {
            await dispatch(getAllShopSettings({ shop_id: response.data.data.id }));
            return response.data.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopProducts = createAsyncThunk("product/all", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.PRODUCT.LIST, payload.data, payload.params);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(me.pending, (state) => {
                state.loading = true;
            })
            .addCase(me.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload.data;
            })
            .addCase(me.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopSettings.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.settings = payload.data;
                const settings = payload.data;
                if (settings && settings.length > 0) {
                    const templateSetting = settings.find((setting: { type: string }) => setting.type === "template");
                    if (templateSetting) {
                        const template = templateSetting.value.template;
                        state.template = template;
                    }
                    const appearance = settings.find((setting: { type: string }) => setting.type === "appearance");
                    if (appearance) {
                        const {
                            favicon_url: faviconUrl,
                            base_theme: baseTheme,
                            brand_color: brandColor,
                            brand_text_color: brandTextColor,
                            font_family: fontFamily,
                            logo_font_family: logoFontFamily,
                            font_weight: fontWeight,
                            element_style: elementStyle,
                        } = appearance.value;
                        const contentBackgroundColor = baseTheme === "dark" ? "#0C111D" : "#FFFFFF";
                        const cardBackgroundColor = baseTheme === "dark" ? "#161B26" : "#FFFFFF";
                        const mainTextColor = baseTheme === "dark" ? "#FAF8F7" : "#0C111D";
                        const borderColorPrimary = baseTheme === "dark" ? "#1E1E1E" : "#E3E7EF";
                        const borderColorSecondary = baseTheme === "dark" ? "#8E8E8E" : "#E3E7EF";
                        const dropdownBackgroundColor = baseTheme === "dark" ? "#1E1E1E" : "#F7F7F8";
                        const productBackgroundColor = baseTheme === "dark" ? "#141414" : "#FFFFFF";

                        let brandBorderRadius = "8px";
                        let brandBorderRadiusSecoundary = "18px";
                        switch (elementStyle) {
                            case "minimal":
                                brandBorderRadius = "8px";
                                brandBorderRadiusSecoundary = "8px";
                                break;
                            case "sharp":
                                brandBorderRadius = "0px";
                                brandBorderRadiusSecoundary = "0px";
                                break;
                            case "curved":
                                brandBorderRadius = "100%";
                                brandBorderRadiusSecoundary = "18px";
                                break;
                            default:
                                break;
                        }
                        document.documentElement.style.setProperty("--brand-color", brandColor);
                        document.documentElement.style.setProperty("--brand-text-color", brandTextColor);
                        document.documentElement.style.setProperty("--content-background-color", contentBackgroundColor);
                        document.documentElement.style.setProperty("--card-background-color", cardBackgroundColor);
                        document.documentElement.style.setProperty("--main-text-color", mainTextColor);
                        document.documentElement.style.setProperty("--brand-font-family", fontFamily);
                        document.documentElement.style.setProperty("--logo-font-family", logoFontFamily);
                        document.documentElement.style.setProperty("--logo-font-weight", fontWeight);
                        document.documentElement.style.setProperty("--brand-border-radius", brandBorderRadius);
                        document.documentElement.style.setProperty("--brand-border-radius-secoundary", brandBorderRadiusSecoundary);
                        document.documentElement.style.setProperty("--border-color-primary", borderColorPrimary);
                        document.documentElement.style.setProperty("--border-color-secondary", borderColorSecondary);
                        document.documentElement.style.setProperty("--dropdown-background-color", dropdownBackgroundColor);
                        document.documentElement.style.setProperty("--product-background-color", productBackgroundColor);

                        if (faviconUrl) {
                            const favicon: any = document.getElementById("favicon");
                            if (favicon) {
                                favicon.href = faviconUrl;
                            }
                        }
                    }
                }
            })
            .addCase(getAllShopSettings.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getShop.pending, (state) => {
                state.loading = true;
            })
            .addCase(getShop.fulfilled, (state, { payload }: any) => {
                state.shop = payload;
                state.loading = false;
            })
            .addCase(getShop.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopProducts.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.products = payload.data;
            })
            .addCase(getAllShopProducts.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export default commonSlice.reducer;

export const currentUser = (state: RootState) => state.Common.user || null;
export const currentShop = (state: RootState) => state.Common.shop || null;
export const currentSettings = (state: RootState) => state.Common.settings || [];
export const allShopProducts = (state: RootState) => state.Common.products || [];
