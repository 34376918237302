import React from "react";
import { Step2PNG } from "src/theme/Images";
const Step2 = () => (
    <>
        <p className="text-[16px] font-normal text-txtAppointmentColor">Select option and Scroll down and select “Add to Home Screen”</p>
        <div className="mt-2 rounded-secondary bg-white">
            <img src={Step2PNG} className="w-full rounded-secondary" alt="step2" />
        </div>
    </>
);

export default Step2;
