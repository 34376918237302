import React from "react";

type StepProgressBarProps = {
    steps: number[];
    currentStep: number;
};

const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps, currentStep }) => (
    <div className="flex items-center gap-[12px] ">
        {steps.map((step, index) => {
            const isCompleted = index < currentStep;
            return <div key={step} className={`flex-1 rounded-[30px] ${isCompleted ? "bg-primary" : "bg-dropdownBackgroundColor"} h-[5px] `}></div>;
        })}
    </div>
);

export default StepProgressBar;
