import React, { FC, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { IOtp, IVerifyOtpProps } from "./Password.interface";
import { useTranslation } from "react-i18next";
import { FaArrowLeftLong } from "react-icons/fa6";
import OTPField from "src/components/OTPField/OTPField";
import { errorCode } from "src/constants/common";
import CustomButton from "src/components/CustomButton";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
type FieldKey = "otp";

const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, handleAuthAction, setAuthData }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const { handleSubmit, control, setError, reset, watch } = useForm<IOtp>({
        defaultValues: {
            otp: "",
        },
    });

    const handlerOtpAgainSend = async () => {
        reset();
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
        }
        axiosPost(API.USER.FORGOT_PASSWORD, payload);
    };

    const otpWatch = watch("otp");

    const handleSave = (data: any) => {
        setLoading(true);
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
                otp: data.otp,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp,
            };
        }
        axiosPost(API.USER.FORGET_PASSWORD_VERIFY_OTP, payload)
            .then((response: any) => {
                const enhancedAuthData = {
                    ...authData,
                    token: response.data.data.token,
                };
                setAuthData(enhancedAuthData);
                handleAuthAction("newPassword");
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                    // setValue('number', new Array(4).fill(''));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="font-primary max-w-[400px] mx-auto lg:w-full">
            <h2 className="login-title-text">{t("Enter the verification code")}</h2>
            <div className="login-subtitle-text flex flex-col">
                <span className=""> {t("A 4-digit code has been sent to you at")}</span>
                <span className="text-txtAppointmentColor font-normal text-base mt-1.5">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
            </div>
            <div className="mx-auto">
                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col my-10 ">
                                <OTPField otp={value} setOtp={onChange} errors={error} />
                                {/* {error && <p className="text-error">{error.message}</p>} */}
                            </div>
                        )}
                    />
                    {authData.isEmail && (
                        <div className="text-txtAppointmentColor font-normal text-base mt-1.5">
                            <span>{t("Tip: Be sure to check your inbox and spam folders")}</span>
                        </div>
                    )}
                    <div className="mt-6 flex flex-col items-center">
                        <div className="flex items-center">
                            <p className="text-textGrey font-medium text-sm leading-5">{t("Didn’t receive code")}?</p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handlerOtpAgainSend}
                                className="text-primary !border-0 !ml-2 !px-0 !bg-transparent text-center flex flex-col ring-0"
                                shadow={false}
                            >
                                {t("Resend")}
                            </CustomButton>
                        </div>
                        <div className="w-full flex justify-center">
                            <CustomButton type="button" className=" !border-0 !bg-transparent text-center ring-0 text-txtAppointmentColor" shadow={false} onClick={() => handleAuthAction("login")}>
                                <FaArrowLeftLong size={14} className="mr-2" /> {t("Back to log in")}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="mt-[37px]">
                        <CustomButton type="submit" isLoading={loading} disabled={otpWatch && otpWatch.length !== 4 ? true : false} primary className="w-full">
                            <span className="pt-1">{t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
