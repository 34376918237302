import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import StepProgressBar from "./StepProgressBar";
import CustomButton from "src/components/CustomButton";
interface IDrawerProps {
    handleClose: () => void;
}

const DownloadApp = ({ handleClose }: IDrawerProps) => {
    const [activeStep, setActiveStep] = useState<number>(1);
    const totalSteps = 5;
    const handleActiveStep = () => {
        if (totalSteps === activeStep) {
            handleClose();
        } else {
            setActiveStep(activeStep + 1);
        }
    };
    const renderStepComponent = () => {
        switch (activeStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            case 5:
                return <Step5 />;
            default:
                break;
        }
    };
    // return <>{renderStepComponent()}</>;
    return (
        <div className=" w-full font-primary">
            <h3 className="text-xl md:text-[28px] leading-9 md:leading-10 font-medium text-txtAppointmentColor">FLAIR Studios App</h3>
            <div className="mt-2 ">
                <p className="text-textGrey text-[16px] leading-5">Save FLAIR Studios as a mobile application</p>
                <div className="pt-4 pb-6">
                    <StepProgressBar steps={Array.from({ length: totalSteps }, (_, i) => i + 1)} currentStep={activeStep} />
                </div>
                {renderStepComponent()}
            </div>
            <div className="items-center w-full pt-1">
                <CustomButton primary onClick={handleActiveStep} className={`w-full mt-5`} type="submit">
                    {totalSteps === activeStep ? "Close" : "Next"}
                </CustomButton>
            </div>
        </div>
    );
};

export default DownloadApp;
