import React, { useState } from "react";

import { IInputPasswordWithLabel } from "./InputPasswordWithLabel.interface";
import { EyeOffIcon, EyeIcon, ErrorCircle } from "src/theme/Images";

const InputPasswordWithLabel = ({ error, onChange, value, name, placeholder, label, index, isFocus }: IInputPasswordWithLabel) => {
    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const handlePasswordHideShow = () => {
        setIsPasswordShow(!isPasswordShow);
    };

    return (
        <div className={`flex flex-col form_group w-full`}>
            <label htmlFor={name} className="text-txtAppointmentColor text-sm font-medium leading-5 tracking-[0.04rem] mb-[3px]">
                {label}
            </label>
            <div className="fl-input-field fl-phone-field !h-10 rounded-secondary border border-borderColorSecondary form_input_control shadow-InputAndButton relative">
                <input
                    className={`rounded-secondary w-full py-0 border-0 text-base focus:ring-0 bg-transparent outline-none font-normal placeholder:text-placeholderTxt placeholder:text-base placeholder:font-normal text-txtAppointmentColor h-[-webkit-fill-available] ${
                        error && "is-invalid "
                    }`}
                    type={isPasswordShow ? "text" : "password"}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e, index)}
                    placeholder={placeholder}
                    autoFocus={isFocus}
                    autoComplete="new-password"
                />

                <div className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-4 " onClick={handlePasswordHideShow}>
                    <img
                        src={isPasswordShow ? EyeOffIcon : error ? ErrorCircle : EyeIcon}
                        className="cursor-pointer min-w-4 h-4"
                        alt="Show password"
                        title={isPasswordShow ? "Hide password" : "Show Password"}
                    />
                </div>
            </div>
        </div>
    );
};

export default InputPasswordWithLabel;
