import React from "react";
import { FiMapPin } from "react-icons/fi";
import BarberDetailCard from "./BarberDetailCard";
import InputWithLabel from "../../../components/InputWithLabel";
import { Search } from "../../../components/SvgColor/svgIcons";
import { ILocation, IShopLocation } from "../../../Interface";
import { IoCloseCircle } from "react-icons/io5";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { XClose } from "@untitled-ui/icons-react/build/cjs";

interface IProps {
    shopLocationData: IShopLocation[];
    searchParam: string | undefined;
    handlerSearchChange: (inputSearch: string) => void;
    handlerNearbyBtnClick: () => void;
    isShopsDataLoading: boolean;
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
    locationCoordinate: ILocation | undefined;
}

const BarberSearchDetailView = ({
    shopLocationData,
    searchParam,
    handlerSearchChange,
    handlerNearbyBtnClick,
    isShopsDataLoading,
    selectedShopData,
    setSelectedShopData,
    locationCoordinate,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <div className="w-full lg:w-[430px] xl:w-[480px] 2xl:w-[525px] 3xl:w-[600px] font-primary">
            <div className="sm:px-5 lg:px-8  pt-0 lg:mt-[34px]">
                <div className="flex flex-col mb-5">
                    <h3 className="location-title-text">{t("Select a location")}</h3>
                    <span className="location-sub-text">{t("Select from any of our locations below to get started with your booking.")}</span>
                </div>
                <div className="flex justify-between gap-2 h-[44px] search-box">
                    <div className="relative w-full">
                        <InputWithLabel
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handlerSearchChange(e.target.value)}
                            name="search_location"
                            placeholder={t("Search")}
                            inputClass=" pl-[44px] !pt-[2px] w-full"
                            value={searchParam ?? ""}
                            buttonIcon={<Search fillColor="var(--icon-color)" />}
                            handlerIconClick={() => handlerSearchChange("")}
                            buttonIconRight={searchParam ? <XClose className="text-secondaryTxt w-5  absolute top-[9px] right-3" /> : <span></span>}
                        />
                    </div>
                    <button
                        className={`flex justify-center gap-1.5 items-center min-w-[120px] border border-primaryAppointment px-4  transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-primaryAppointment  rounded-secondary ${
                            locationCoordinate ? "bg-transparent" : "bg-transparent"
                        }`}
                        onClick={handlerNearbyBtnClick}
                    >
                        <FiMapPin className="text-lg text-primaryAppointment" />
                        <span className="font-semibold text-base bg-transparent text-primaryAppointment tracking-[0.03rem]"> {t("Nearby")}</span>
                    </button>
                </div>
            </div>
            <div className="w-[90%] mx-auto mt-2 mb-2">
                <BarLoader isLoading={isShopsDataLoading} />
            </div>
            {shopLocationData.length ? (
                <div className="lg:px-8 px-0 sm:px-5 xl:px-8 mt-[22px] lg:mt-8 left_sidebar_content overflow-auto lg:pb-[50px]">
                    {shopLocationData.map((location: IShopLocation, index: number) => (
                        <BarberDetailCard location={location} key={index} selectedShopData={selectedShopData} setSelectedShopData={setSelectedShopData} />
                    ))}
                </div>
            ) : (
                !isShopsDataLoading && (
                    <div className="left_sidebar_content flex items-center mt-[22px] lg:mt-8 justify-center flex-1">
                        <p className="text-center text-textGrey text-base font-normal">{t("There are no available locations that match your search.")}</p>
                    </div>
                )
            )}
        </div>
    );
};

export default BarberSearchDetailView;
