import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { PlaceholderImgPNG, InstagramSVG } from "src/theme/Images";
import { convertToHumanReadable, onError } from "../../utils/global-functions";
import { ISpecialistImageGallary } from "../../Interface";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import { INSTAGRAM_URL } from "src/constants/common";

const SpecialistDetail = () => {
    const { t } = useTranslation();
    const uiState = useAppSelector((data) => data.UiStates);

    return (
        <div className="flex flex-col rounded-t-3xl xl:rounded-3xl w-full  lg:h-full  relative">
            <div className="flex justify-between items-center">
                <div className="flex flex-row gap-3 rounded-primary">
                    <img
                        className="rounded-primary w-[50px] h-[50px] object-cover"
                        src={uiState.showSpecialistProfileModalData?.profile_image_url}
                        alt=""
                        onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)}
                    />
                    <div className="content pt-[2px]">
                        <h2 className="font-medium text-base text-txtAppointmentColor tracking-[0.03rem]">
                            {`${uiState.showSpecialistProfileModalData?.first_name} ${uiState.showSpecialistProfileModalData?.last_name}`}
                        </h2>
                        <p className="font-normal text-sm text-textGrey tracking-[0.03rem]">{uiState.showSpecialistProfileModalData?.staff_role?.name}</p>
                    </div>
                </div>
                <CustomButton outlineSecondary className="lg:hidden !h-[36px] rounded-lg">
                    {uiState.showSpecialistProfileModalData?.instagram_link && (
                        <a
                            className="flex gap-3 w-full items-center bg-inputbg  rounded-xl cursor-pointer justify-between"
                            href={`${INSTAGRAM_URL}${uiState.showSpecialistProfileModalData?.instagram_link}`}
                            target="_blank"
                        >
                            <div className="flex gap-2">
                                <img src={InstagramSVG} className="w-[20px]" alt="" />
                                <p className="text-sm font-normal text-textGrey  flex justify-between items-center tracking-[0.03rem]">@{uiState.showSpecialistProfileModalData?.instagram_link}</p>
                            </div>
                        </a>
                    )}
                </CustomButton>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-between items-center">
                <div className="font-normal flex-1 text-xs text-textGrey text-justify mr-3 tracking-[0.03rem]">{uiState.showSpecialistProfileModalData?.about}</div>
                <div className="hidden lg:block ">
                    <CustomButton outlineSecondary className=" !h-[36px] rounded-lg ">
                        {uiState.showSpecialistProfileModalData?.instagram_link && (
                            <a
                                className="flex gap-3  w-full items-center bg-inputbg  rounded-xl  cursor-pointer justify-between tracking-[0.03rem]"
                                href={`${INSTAGRAM_URL}${uiState.showSpecialistProfileModalData?.instagram_link}`}
                                target="_blank"
                            >
                                <div className="flex gap-2">
                                    <img src={InstagramSVG} className="w-[20px]" alt="" />
                                    <p className="text-sm font-normal text-textGrey  flex justify-between items-center tracking-[0.03rem]">@{uiState.showSpecialistProfileModalData?.instagram_link}</p>
                                </div>
                            </a>
                        )}
                    </CustomButton>
                </div>
            </div>
            {/* image hoi aa 2 class add max-h-[295px] lg:max-h-[245px] and no hoi thayre h-full */}
            <div className="flex flex-col mt-8">
                <div className="font-medium text-base text-txtAppointmentColor tracking-[0.03rem]">Service provided by {uiState.showSpecialistProfileModalData?.full_name}</div>
                <div className="h-full max-h-[295px] lg:max-h-[245px] overflow-y-scroll scrollbar-hide mt-4 flex flex-row gap-2 lg:gap-4 flex-wrap tracking-[0.03rem]">
                    {uiState.showSpecialistProfileModalData?.services?.map((item: any, index: number) => (
                        <div key={index} className="w-[162px] h-[124px] lg:w-[192px] lg:h-[140px] p-3 lg:p-5 border border-borderColorPrimary rounded-secondary justify-between flex flex-col">
                            <div className="flex flex-col gap-1">
                                <div className="text-sm font-bold text-txtAppointmentColor tracking-[0.03rem]">{item?.name}</div>
                                <div className="text-xs font-normal text-textGrey tracking-[0.03rem]">
                                    {item?.shop_staff_service?.duration && convertToHumanReadable(item?.shop_staff_service?.duration)}
                                </div>
                            </div>
                            <div className="flex flex-row justify-between items-center w-full tracking-[0.03rem]">
                                <div className="text-xs font-medium text-textGrey tracking-[0.04rem]">{item.shop_staff_service.starting_price ? t("Starting price") : t("Price")}</div>
                                <div className="bg-contentBackground border rounded-secondary border-borderColorPrimary max-w-max h-[22px] px-[6px] flex justify-center ">
                                    <div className="text-txtAppointmentColor font-semibold flex items-center gap-[2px]">
                                        <span className="text-[13px]">$</span>
                                        <span className="text-[15px]">{item.shop_staff_service.price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {uiState.showSpecialistProfileModalData?.galleries && uiState.showSpecialistProfileModalData?.galleries?.length > 0 && (
                <div className="flex flex-col mt-4">
                    <div className="font-medium text-base text-txtAppointmentColor tracking-[0.03rem]">Gallery of {uiState.showSpecialistProfileModalData?.first_name}</div>
                    <div className=" mt-4 flex flex-row gap-3 lg:gap-4 lg:flex-wrap overflow-x-auto w-full scrollbar_add pb-2">
                        {uiState.showSpecialistProfileModalData?.galleries?.map((item: ISpecialistImageGallary, index: number) => (
                            <div key={index} className="min-w-[74px] min-h-[74px] lg:w-[192px] lg:h-[153px]  rounded-lg ">
                                <img src={item?.image_url} alt="" className=" object-cover w-full h-full rounded-lg" onError={onError} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpecialistDetail;
