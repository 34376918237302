import React, { Fragment, useEffect, useState } from "react";
import { IoFilterOutline } from "react-icons/io5";
import { IShopLocationCategory, ISpecialistService } from "../../../Interface";
import { toast } from "react-toastify";
import { errorCode } from "../../../constants/common";
import { API } from "../../../constants/api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import ServiceCardComponent from "./ServiceCardComponent";
import { getBookingInfo, setStep, setTempStore } from "../Booking.slice";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../bookAppointment.interface";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { Collapse } from "src/components/Collapse/Collapse";
import { Dropdown, Spinner } from "flowbite-react";
import { ArrowLeft, FilterFunnel02 } from "@untitled-ui/icons-react/build/cjs";

type Staff = {
    id: number;
    created_by: any;
    plan_id: any;
    stripe_customer_id: string;
    stripe_account_id: string;
    social_id: any;
    first_name: string;
    last_name: string;
    username: any;
    phone_country_code: string;
    phone: string;
    email: string;
    birth_date: any;
    gender: any;
    country: string;
    language: string;
    about: string;
    profile_image_name: string;
    instagram_link: string;
    is_notification: boolean;
    register_type: string;
    is_verified_stripe_account: boolean;
    is_password_set: boolean;
    is_pin_set: boolean;
    notification: {
        whats_new: boolean;
        booking_confirm: boolean;
        booking_canceled: boolean;
        first_time_client: boolean;
        booking_reschedule: boolean;
    };
    login_at: string;
    email_verified_at: string;
    phone_verified_at: string;
    deleted_at: any;
    created_at: string;
    updated_at: string;
    laravel_through_key: number;
    full_name: string;
    profile_image_url: string;
};

type Price = {
    id: number;
    shop_id: number;
    shop_admin_id: number;
    shop_service_id: number;
    duration: string;
    price: string;
    starting_price: boolean;
    status: boolean;
    description: any;
    deleted_at: any;
    created_at: string;
    updated_at: string;
};

type Item = {
    id: number;
    shop_id: number;
    shop_category_id: number;
    parent_id: number | null;
    name: string;
    tags: any;
    description: string;
    type: string;
    is_active: boolean;
    deleted_at: any;
    created_at: string;
    updated_at: string;
    parent?: Item | null;
    staff: Staff[];
    price: Price;
    children?: Item[];
};
const SelectServices = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoading, setIsLoading] = useState(true);
    const [allServices, setAllServices] = useState<ISpecialistService[]>([]);
    const [filteredServices, setFilteredServices] = useState<ISpecialistService[]>([]);
    const [categoryLists, setCategoryLists] = useState<IShopLocationCategory[]>([]);
    const [isShopLocationServicesLoading, setIsShopLocationServicesLoading] = useState(false);
    const [isShopLocationCategoryLoading, setIsShopLocationCategoryLoading] = useState(false);
    const [categoryFilterValue, setCategoryFilterValue] = useState<any | null>(null);
    const [searchFilterValue, setSearchFilterValue] = useState<string>("");

    useEffect(() => {
        const initialLoad = async () => {
            await dispatch(setTempStore({ selectedServices: bookingState.selectedServices }));
            await loadCategories();
            await loadServices();
            setIsLoading(false);
        };
        initialLoad();
    }, []);

    useEffect(() => {
        const filteredRecords = allServices.filter((serviceRecord: ISpecialistService) => {
            let searchMatched = false;
            let categoryMatched = false;

            // search value matching
            if (searchFilterValue !== "") {
                if (serviceRecord.name.toLocaleLowerCase().includes(searchFilterValue.toLocaleLowerCase())) {
                    searchMatched = true;
                }
            } else {
                searchMatched = true;
            }

            // category filtering
            if (categoryFilterValue && categoryFilterValue.value) {
                categoryMatched = serviceRecord.shop_category_id === categoryFilterValue.value;
            } else {
                categoryMatched = true;
            }
            return searchMatched && categoryMatched;
        });
        const groupByParent = groupByParentId(filteredRecords as unknown as Item[]);
        setFilteredServices(groupByParent as unknown as ISpecialistService[]);

        // setFilteredServices(filteredRecords);
    }, [categoryFilterValue, searchFilterValue]);

    const groupByParentId = (data: Item[]): Item[] => {
        // Create a map to hold the grouped data
        const map: { [key: number]: Item } = {};

        // Loop through the data and initialize the map
        data.forEach((item) => {
            if (!map[item.id]) {
                map[item.id] = { ...item, children: [] };
            }
            if (item.parent_id) {
                if (!map[item.parent_id]) {
                    map[item.parent_id] = { children: [] } as unknown as Item;
                }
                map[item.parent_id].children!.push(map[item.id]);
            }
        });

        // Extract the grouped data into an array
        return Object.values(map).filter((item) => !item.parent_id);
    };

    const loadServices = async () => {
        try {
            setIsShopLocationServicesLoading(true);
            const payloadData = {
                staff_id: bookingState.selectedSpecialist?.id,
                booking_date: bookingState.selectedBookingDate,
                booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
                booking_id: bookingState.isModifyingAppointment ? bookingState.modifyingAppointmentId : null,
            };
            const urlParamObj = {
                shop_id: bookingState.selectedShopLocation?.shop_id,
                location_id: bookingState.selectedShopLocation?.id,
            };
            const response = await axiosPost(API.SERVICE.LIST, payloadData, urlParamObj);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                const groupedData = await groupByParentId(response.data.data);
                setAllServices(response.data.data);
                setFilteredServices(groupedData as unknown as ISpecialistService[]);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            setIsShopLocationServicesLoading(false);
        }
    };

    const loadCategories = async () => {
        try {
            setIsShopLocationCategoryLoading(true);
            const payloadObj = {
                shop_id: bookingState.selectedShopLocation?.shop_id,
                location_id: bookingState.selectedShopLocation?.id,
            };
            const response = await axiosGet(API.CATEGORY.LIST, {}, payloadObj);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setCategoryLists(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            setIsShopLocationCategoryLoading(false);
        }
    };

    const handleClose = async () => {
        await dispatch(setStep(EBookingTabState.TAB_MENU));
        await dispatch(setTempStore({ selectedServices: [] }));
    };
    const handleRemoveService = async (id: any) => {
        const removeFromTemp = bookingState?.tempStore?.selectedServices?.filter((_, idx) => idx !== id);
        await dispatch(setTempStore({ selectedServices: removeFromTemp }));
    };
    return (
        <div className="h-full relative">
            <div className="flex flex-row w-full justify-between items-center mt-4  mb-4">
                <div className="title text-txtcolor font-bold text-2xl leading-[128%] md:mt-0 flex gap-3 items-center w-full">
                    <div
                        className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton"
                        onClick={handleClose}
                    >
                        <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                    </div>
                    <span className="location-title-text text-[24px]">Select services </span>
                </div>
                {/* <div className="mt-2 md:mt-0 w-full md:max-w-[100px] md:block fl-tab-btn-view ">
                    <div className={`rounded-md text-sm font-normal border h-[40px] px-2.5 py-1 items-center justify-center flex pt-1.5 border-headerBorderColor text-textGrey `}>
                        <IoFilterOutline color="#8C9CBB" size={18} className="mr-2" />
                        Filter
                    </div>
                </div> */}
                <div className="flex ">
                    <Dropdown
                        className="bg-dropdownBackgroundColor border-dropdownBackgroundColor"
                        theme={{
                            arrowIcon: "ml-2 h-4 w-4",
                            content: "py-1 focus:outline-none bg-dropdownBackgroundColor !right-0 fixed z-50 rounded-secondary shadow-InputAndButton px-2.5 py-2 space-y-1",
                        }}
                        label=""
                        placement="bottom-start"
                        dismissOnClick={true}
                        inline
                        renderTrigger={() => (
                            <button
                                type="button"
                                className="min-w-[38px] h-[38px] lg:w-[94px] lg:h-[40px] border border-borderColorSecondary lg:border-borderColorSecondary rounded-secondary flex items-center justify-center lg:mt-0"
                            >
                                <FilterFunnel02 className="w-[20px] h-[20px] lg:hidden text-textGrey" />
                                <IoFilterOutline color="#8C9CBB" size={18} className="hidden lg:block mr-2" />
                                <span className="hidden lg:block text-textGrey text-base font-normal">Filter</span>
                            </button>
                        )}
                    >
                        {categoryLists.map((categoryList) => (
                            <Dropdown.Item
                                theme={{
                                    container: "right-0",
                                    icon: "text-textGrey mr-3 h-4 w-4 bg-black ",
                                    base: `${
                                        categoryFilterValue?.value === categoryList.id && " bg-contentBackground rounded-secondary text-txtcolor focus:outline-none"
                                    }  flex w-[250px] md:w-[210px] right-0 tracking-[0.02rem] cursor-pointer items-center justify-start px-2  py-2 text-sm text-txtcolor active:bg-contentBackground active:rounded-secondary hover:rounded-secondary focus:rounded-secondary hover:bg-contentBackground focus:bg-contentBackground focus:outline-none focus:ring-0`,
                                }}
                                key={categoryList.id}
                                onClick={() => {
                                    categoryFilterValue?.value === categoryList.id ? setCategoryFilterValue(null) : setCategoryFilterValue({ label: categoryList.name, value: categoryList.id });
                                }}
                            >
                                {categoryList.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>
            </div>
            <div className="w-full md:w-1/2 mt-2 mb-2">
                <BarLoader isLoading={isShopLocationServicesLoading} />
            </div>
            {/* <div className="mt-2 md:mt-0 w-full md:w-[320px] md:block">
                    <InputWithLabel
                        buttonIcon={searchFilterValue ? <IoCloseCircle className="text-secondaryTxt text-xl" /> : <IoSearchOutline color="#8C9CBB" size={18} className="mr-1" />}
                        handlerIconClick={() => setSearchFilterValue("")}
                        type="text"
                        inputClass="pl-[41px]"
                        placeholder={t("Search a services")}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchFilterValue(e.target.value)}
                        value={searchFilterValue}
                        name="firstname"
                        disabled={isShopLocationServicesLoading}
                    />
                </div> */}
            <div className="flex flex-wrap pb-5"></div>

            {/* {uiState.isMobile ? (
                <div>
                    <div className="-mx-[10px] h-[calc(100vh-250px)] lg:h-[60vh] overflow-scroll scrollbar-hide py-5 pb-14 2xl:pb-24 content-baseline">
                        {bookingState?.tempStore?.selectedServices?.map((serviceData: ISpecialistService) => (
                            <ServiceCardMobileComponent key={`selected-${serviceData.id}`} serviceData={serviceData} />
                        ))}
                        {filteredServices.map((serviceData: ISpecialistService) => (
                            <ServiceCardMobileComponent key={serviceData.id} serviceData={serviceData} />
                        ))}
                        {filteredServices.length <= 0 && !isShopLocationServicesLoading && (
                            <div className=" justify-center flex w-full h-full items-center">
                                <h1 className="text-txtcolor font-bold  text-base ">{t("No available services")}</h1>
                            </div>
                        )}
                    </div>
                    <ManageSubmit />
                </div>
            ) : ( */}
            <div className="flex -lg:mx-[10px] max-h-[calc(100vh-270px)] pb-[70px] lg:max-h-[calc(100vh-230px)] lg:h-[calc(100%-90px)] overflow-scroll scrollbar-hide flex-wrap md:pb-0 content-baseline ">
                {isLoading ? (
                    <div className="flex justify-center just w-full ">
                        <Spinner size="xl" className="" />
                    </div>
                ) : filteredServices.length <= 0 && !isShopLocationServicesLoading ? (
                    <div className=" justify-center flex w-full h-full items-center">
                        <h1 className="text-center text-textGrey text-base font-normal ">{t("No available services")}</h1>
                    </div>
                ) : (
                    <div className="w-full gap-4 flex flex-col">
                        {bookingState?.tempStore?.selectedServices?.map((serviceData: ISpecialistService, index: number) => (
                            <ServiceCardComponent
                                key={`service-${serviceData.id}-${index}`}
                                serviceData={serviceData}
                                removeable={true}
                                handleRemoveService={() => handleRemoveService(index)}
                                isSelected
                            />
                        ))}
                        {bookingState?.tempStore?.selectedServices.length > 0 && (
                            <div className="font-medium font-base text-txtAppointmentColor pl-[3px] tracking-[0.04rem]"> {t("Additional services you want to add")}</div>
                        )}
                        {filteredServices.map((serviceData: ISpecialistService, index: number) => (
                            <Fragment key={`${serviceData.id}-${index}`}>
                                {serviceData?.children && serviceData?.children?.length > 0 ? (
                                    <Collapse
                                        title={serviceData?.children[0]?.parent?.name}
                                        description={`${serviceData?.children?.length} Options`}
                                        isCollapsed={false}
                                        classNames="w-full text-txtcolor"
                                    >
                                        <div className="border-t border-t-borderColorPrimary mt-4">
                                            <div className="mt-4 gap-4 flex flex-col">
                                                {serviceData.children.map((child: any) => (
                                                    <ServiceCardComponent key={child.id} serviceData={child} />
                                                ))}
                                            </div>
                                        </div>
                                    </Collapse>
                                ) : (
                                    <ServiceCardComponent serviceData={serviceData} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                )}
                {uiState.isMobile && <ManageSubmit />}
            </div>
            {/* )} */}
        </div>
    );
};

export default SelectServices;
