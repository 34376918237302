import React from "react";
import { Step3PNG } from "src/theme/Images";
const Step3 = () => (
    <>
        <p className="text-[16px] font-normal text-txtAppointmentColor">Add a name</p>
        <div className="mt-2 bg-white rounded-secondary">
            <img src={Step3PNG} className="w-full rounded-secondary" alt="step3" />
        </div>
    </>
);

export default Step3;
