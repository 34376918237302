import axios from "axios";
import { logout } from "./global-functions";
import { LOCAL_STORAGE_KEYS, somethingWentWrongError } from "../constants/common";

const ApiBaseUrl = process.env.REACT_APP_API_URL;

export type IRequestClient = {
    data: any;
};
const getFormData = (object: any) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
};

export const axiosApi = axios.create({
    baseURL: ApiBaseUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

axiosApi.interceptors.request.use(
    (config: any) => {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.authToken)) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.authToken)}`;
        }
        return config;
    },
    (error: any) => Promise.reject(error),
);

export const axiosPatch = async (url: string, data: any, params?: object) => {
    const response: IRequestClient = {
        data: null,
    };

    try {
        if (params && Object.keys(params).length !== 0) {
            url = generateURLWithDynamicPathParam(url, params);
        }
        const result = await axiosApi.patch(url, data);
        response.data = result.data;
    } catch (e: any) {
        if (e.response) {
            if (e.response?.status && e.response?.status === 401) {
                response.data = e.response?.data;
                logout();
            } else {
                throw e;
            }
        }
    }
    return response;
};

export const axiosPost = async (url: string, data: any, params?: object, formData = false) => {
    const response: IRequestClient = {
        data: null,
    };

    try {
        if (params && Object.keys(params).length !== 0) {
            url = generateURLWithDynamicPathParam(url, params);
        }
        if (formData) {
            data = getFormData(data);
            (axiosApi.defaults.headers as any)["Content-Type"] = "multipart/form-data";
            const result = await axiosApi.post(url, data);
            response.data = result.data;
        } else {
            const result = await axiosApi.post(url, data);
            response.data = result.data;
        }
    } catch (e: any) {
        if (e.response) {
            if (e.response?.status && e.response?.status === 401) {
                response.data = e.response?.data;
                logout();
            } else {
                throw e;
            }
        }
    }
    return response;
};

export const axiosPut = async (url: string, data: any, params?: object) => {
    let response: any = {};
    try {
        if (params && Object.keys(params).length !== 0) {
            url = generateURLWithDynamicPathParam(url, params);
        }

        const result = await axiosApi.put(url, data);

        response.status = true;
        response.data = result.data;
    } catch (e: any) {
        if (e.response) {
            if (e.response.status === 400) {
                response.status = false;
                response.message = e.response.data.message;
            } else if (e.response?.status === 401) {
                response.status = false;
                response.message = "Unauthorized";
                logout();
            } else if (e.response.status === 403) {
                response.status = false;
                response.message = e.response.data.message;
            } else if (e.response.status === 500) {
                response.status = false;
                response.message = "Internal server error";
            } else {
                response.status = false;
                response.message = somethingWentWrongError;
            }
        }
    }
    return response;
};

export const axiosGet = async (url: any, params?: object, urlParams?: object) => {
    const response: IRequestClient = {
        data: null,
    };
    try {
        if (urlParams && Object.keys(urlParams).length !== 0) {
            url = generateURLWithDynamicPathParam(url, urlParams);
        }
        const result = await axiosApi.get(url, { params });
        response.data = result.data;
    } catch (e: any) {
        if (e.response) {
            if (e.response?.status === 401) {
                response.data = e.response?.data;
                logout();
            } else {
                response.data = e.response?.data;
            }
        }
    }
    return response;
};

export const axiosDelete = async (url: string, data: any, params?: object) => {
    let response: any = {};
    if (params && Object.keys(params).length !== 0) {
        url = generateURLWithDynamicPathParam(url, params);
    }

    try {
        const result = await axiosApi.delete(url, data);

        response.status = true;
        response.data = result.data;
    } catch (e: any) {
        if (e.response.status === 400) {
            response.status = false;
            response.message = e.response.data.message;
        } else if (e.response?.status === 401) {
            response.status = false;
            response.message = "Unauthorized";
            logout();
        } else if (e.response.status === 500) {
            response.status = false;
            response.message = "Internal server error";
        } else {
            response.status = false;
            response.message = somethingWentWrongError;
        }
    }
    return response;
};

const generateURLWithDynamicPathParam = (url: string, pathParams: any) => {
    for (const param in pathParams) {
        url = url.replace(`{${param}}`, pathParams[param]);
    }
    return url;
};
