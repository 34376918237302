import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { getBookingInfo } from "../../Booking.slice";

interface IBasketCalculation {
    staticInfo: IAppointmentBooking;
}

const BookedBasketCalculation = ({ staticInfo }: IBasketCalculation) => {
    const { t } = useTranslation();
    const booking = useAppSelector(getBookingInfo);

    return (
        <div>
            {booking?.selectedPromocode?.name && (
                <div className="flex justify-between mt-5">
                    <h1 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-normal text-textGrey tracking-[0.03rem]">{t("Promo code")}</h1>
                    <span className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium text-txtAppointmentColor tracking-[0.03rem]">
                        {booking?.selectedPromocode?.name}
                    </span>
                </div>
            )}
            <div className="flex justify-between mt-3">
                <h1 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-normal text-textGrey tracking-[0.03rem]">{t("Subtotal")}</h1>
                <span className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium text-txtAppointmentColor tracking-[0.03rem]">
                    ${staticInfo.subtotal}
                </span>
            </div>
            <div className="flex justify-between mt-3">
                <h1 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-normal text-textGrey tracking-[0.03rem]">{t("Taxes and fees")}</h1>
                <span className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium text-txtAppointmentColor tracking-[0.03rem]">
                    ${staticInfo.taxes_and_fees}
                </span>
            </div>
            <hr className="border-borderColorPrimary my-4" />
            <div className="font-bold mt-2 flex justify-between">
                <h1 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium text-txtAppointmentColor tracking-[0.03rem]">{t("Total")}</h1>
                <span className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium text-txtAppointmentColor tracking-[0.03rem]">
                    ${staticInfo.total}
                </span>
            </div>
        </div>
    );
};

export default BookedBasketCalculation;
