import React, { FC, useEffect, useState } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import InputWithLabel from "../../../components/InputWithLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { errorCode } from "../../../constants/common";
import { axiosPatch } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { convertBase64ToFile, getShortName } from "src/utils/global-functions";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { IProfileProps, ISocialProfile } from "../Auth.interface";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip } from "flowbite-react";
import { PlaceholderImgPNG } from "src/theme/Images";
import CustomButton from "src/components/CustomButton";
import { currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { usePhone } from "src/hooks/usePhone";
import PhoneOrEmail from "src/components/PhoneOrEmail";

const SocialProfile: FC<IProfileProps> = ({ setAuthData, handleAction }) => {
    const { t } = useTranslation();
    const { isPhoneValid } = usePhone();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoading, setIsLoading] = useState(false);
    const [referralConfig, setReferralConfig] = useState({ status: false, referee: 0, refferer: 0, referee_type: "percentage", refferer_type: "percentage" });
    const [errorImage, setErrorImage] = useState({
        profile: false,
    });

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        phone_country_code: Yup.string().required(t("This field is required")),
        phone: Yup.string()
            .required(t("This field is required"))
            .test({
                message: t("Invalid phone number"),
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    const isValid = isPhoneValid(value as string, phoneCountryCode);
                    return isValid;
                },
            }),
        profile_image_name: Yup.object().nullable(),
        referral_code: Yup.string().notRequired(),
    }).required();

    const { handleSubmit, control, setError, setValue, watch } = useForm<ISocialProfile>({
        resolver: yupResolver(schema) as Resolver<ISocialProfile>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            phone_country_code: user?.phone_country_code || "",
            phone: user?.phone || "",
            profile_image_name: null,
            referral_code: "",
        },
    });
    const watchProfileImage = watch("profile_image_name");
    const firstName = watch("first_name");
    const lastName = watch("last_name");

    useEffect(() => {
        const shopSetting = shop.shop_settings.find((shop_setting: { type: string }) => shop_setting.type === "referral_config");
        if (shopSetting) {
            setReferralConfig({
                status: shopSetting.status || false,
                referee: shopSetting.value.referee || 0,
                referee_type: shopSetting.value.referee_type || "percentage",
                refferer: shopSetting.value.refferer || 0,
                refferer_type: shopSetting.value.refferer_type || "percentage",
            });
        }
    }, []);

    const handleSave = async (payloadData: ISocialProfile) => {
        setIsLoading(true);

        let imageStatus = errorCode.updateSuccess;
        if (watchProfileImage?.file && watchProfileImage?.name) {
            const logoImageResponse: any = await s3Upload(watchProfileImage?.file, `${s3Path.USER_PROFILE}${watchProfileImage?.name}`);
            imageStatus = logoImageResponse?.status;
        }

        if (imageStatus === errorCode.updateSuccess) {
            const data = {
                ...payloadData,
                profile_image_name: watchProfileImage?.name,
            };
            axiosPatch(API.USER.SOCIAL_ACCOUNT, data, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(me());
                    setAuthData((old: any) => ({ ...old, phone: data.phone, phone_country_code: data.phone_country_code }));
                    handleAction("otp")();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof ISocialProfile, {
                                    type: "manual",
                                    message: response.data[field][0],
                                });
                            });
                            return;
                        }
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        reader.onloadstart = () => {};
        reader.onloadend = () => {
            const file = convertBase64ToFile(reader.result);
            if (file && file.filename && file.convertedFile) {
                setValue("profile_image_name", {
                    file: file.convertedFile as File,
                    url: reader.result as string,
                    name: file.filename,
                });
            }
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    const onErrorImage = (name: string) => () => {
        setErrorImage((old) => ({ ...old, [name]: true }));
    };

    return (
        <div className="content font-primary">
            <div className="mb-5">
                <h1 className="login-title-text">{t("Complete your profile")}</h1>
            </div>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className={`flex flex-wrap justify-center -mx-4 overflow-y-scroll scrollbar-hide  h-[calc(100vh-368px)] `}>
                    <div className="px-4">
                        <div className="flex flex-wrap gap-4">
                            <Controller
                                name="profile_image_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <div className="relative flex gap-5 items-center">
                                            <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />

                                            <label htmlFor="image" className="relative h-[64px] w-[64px] rounded-full">
                                                {!errorImage.profile && value && value.url ? (
                                                    <img
                                                        src={value.url}
                                                        alt=""
                                                        onError={onErrorImage("profile")}
                                                        className="rounded-full bg-[#cbe3f2] flex justify-center items-center border-white object-cover font-bold h-[64px] w-[64px] shadow-custom text-[32px]"
                                                    />
                                                ) : firstName && lastName ? (
                                                    <div className="rounded-full bg-[#cbe3f2] shadow-custom flex justify-center items-center border-white  text-[#4b4b37] font-bold h-[64px] w-[64px] ">
                                                        <span className="text-[24px] leading-[36px] pt-1 font-medium">{getShortName(`${firstName} ${lastName}`)}</span>
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={PlaceholderImgPNG}
                                                        alt=""
                                                        className="rounded-full bg-[#cbe3f2] flex justify-center items-center border-white object-cover font-bold h-[64px] w-[64px] shadow-custom text-[32px]"
                                                    />
                                                )}
                                            </label>
                                            <div className="flex flex-col items-center">
                                                <span className="flex text-txtAppointmentColor text-base leading-[22.4px] pt-0.5 font-medium mb-1">Your profile picture</span>
                                                <label htmlFor="image" className="text-primaryAppointment text-base cursor-pointer leading-[22.4px] font-medium">
                                                    Add profile picture
                                                </label>
                                            </div>
                                        </div>
                                        {error && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel label={t("First Name")} type="text" placeholder={t("Enter your first name")} handleChange={onChange} value={value} name="first_name" />
                                        {error && <p className="text-red-500">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel label={t("Last Name")} type="text" placeholder={t("Enter your last name")} handleChange={onChange} value={value} name="last_name" />
                                        {error && <p className="text-red-500">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="w-full">
                                        <label className="text-txtAppointmentColor text-sm font-normal leading-5 mb-4">{t("Phone")}</label>
                                        <PhoneOrEmail
                                            singleType="phone"
                                            inputType="phone"
                                            onNumberChange={({ data: { country, code, number } }: any) => {
                                                setValue("phone", `+${code}${number}`);
                                                setValue("phone_country_code", country);
                                            }}
                                        />
                                        {error && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                            {referralConfig.status && (
                                <Controller
                                    name="referral_code"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="mb-5 md:w-full">
                                            <div className="flex flex-row  mb-1.5 items-center">
                                                <span className="text-txtAppointmentColor text-sm font-normal leading-5">
                                                    Referral code <span className="text-textGrey">(Optional)</span>
                                                </span>
                                                <Tooltip
                                                    content={
                                                        <div>
                                                            <p className=" text-primaryAppointment text-xs font-normal">
                                                                {t("Receive 0% off any service when you refer a friend!", {
                                                                    value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                                })}
                                                            </p>
                                                        </div>
                                                    }
                                                    trigger="hover"
                                                    style={"light" as any}
                                                    className="w-[185px] bg-[#EDF1FD] p-3 rounded-lg "
                                                    placement="right"
                                                >
                                                    <BiInfoCircle className="text-primaryAppointment ml-1.5 cursor-pointer w-[16px]" />
                                                </Tooltip>
                                            </div>
                                            <InputWithLabel
                                                // label={t("Referral code (Optional)")}
                                                type="text"
                                                placeholder={t("Enter code")}
                                                handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                                value={value ?? ""}
                                                name="referral_code"
                                            />
                                            {error && <p className="text-red-500">{error.message}</p>}
                                            <p className="text-secondaryTxt text-xs font-normal leading-4 -tracking-[0.03px] mt-2">
                                                {t("Enter a referral code to receive")}
                                                &nbsp;
                                                <span className="font-semibold">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>
                                                &nbsp;
                                                {t("your first appointment")}
                                            </p>
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="border-t border-textGrey pb-3 pt-3">
                    <p className="text-txtcolor leading-[20px] font-normal text-sm   text-justify ">
                        {t("By signing up, I agree to the")}&nbsp;
                        <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary">
                            {t("Terms of Service")}
                        </a>
                        &nbsp;{t("and")}&nbsp;
                        <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary">
                            {t("Privacy Policy")}
                        </a>
                        &nbsp;
                        {t("and I would like to be alerted for personalized offerings, discounts, and more.")}
                    </p>
                </div>
                <CustomButton primary type="submit" size="w-full" className="mt-5" isLoading={isLoading} disabled={isLoading}>
                    {uiState.isMobile ? t("Next") : t("Continue")}
                </CustomButton>
            </form>
        </div>
    );
};

export default SocialProfile;
