import React from "react";
import { PlaceholderImgPNG } from "src/theme/Images";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setBookingInitialState, setSelectedShopLocation } from "../../../BookAppointment/Booking.slice";
import { IShopLocation } from "../../../../Interface";
import { ROUTES } from "../../../../constants/routes";

interface IProps {
    location: IShopLocation;
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
}

const BarberDetailCard = ({ location, selectedShopData, setSelectedShopData }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector((data) => data.Booking);
    return (
        <div
            onMouseEnter={() => setSelectedShopData(location)}
            onMouseLeave={() => setSelectedShopData(undefined)}
            onClick={() => {
                if (location.id !== bookingState.selectedShopLocation?.id || bookingState.isModifyingAppointment) {
                    dispatch(setBookingInitialState());
                }
                dispatch(setSelectedShopLocation(location));
                navigate(ROUTES.BOOK_APPOINTMENT);
            }}
        >
            <label
                htmlFor={`${location.id}`}
                className={`bg-cardColor flex shadow-card mb-3 relative rounded-secondary  cursor-pointer p-3 min-h-[95px] ${
                    selectedShopData?.id === location.id ? "border-primaryAppointment border" : "border-borderColorPrimary border"
                }`}
            >
                <div className="w-[90px] sm:w-[112px] lg:w-[112px] xl:w-[120px] ">
                    <img
                        src={location.location_image_url}
                        alt=""
                        className=" md:w-[100px] md:h-[71px] object-cover w-[71px] h-[71px] rounded-secondary"
                        onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)}
                    />
                </div>
                <div className="flex gap-5 md:flex-1 w-3/4">
                    <div className="content p-0  w-full flex flex-col justify-center">
                        <h2 className="text-lg  font-medium text-txtcolor leading-[140%] tracking-[0.03rem]">{location.name}</h2>
                        <p className="mt-2 line-clamp-2 font-medium  text-sm  text-textGrey leading-5 tracking-[0.03rem]">{`${location.street}, ${location.city}, ${location.country} ${location.postal_code}`}</p>
                        {/* <div className="flex mt-[8px] lg:mt-[5px] lg:hidden items-center">
                            <PiMapPin size={18} className="text-primaryAppointment  mr-1 self-center" />

                            <span className="text-xs text-primaryAppointment  -tracking-[0.12px] font-medium">{t("Directions")}</span>
                            <FiArrowRight size={18} className="text-primaryAppointment  ms-3 self-center" />
                        </div> */}
                    </div>
                    {/* <FiArrowRight size={24} className="text-primaryAppointment  mr-5 self-center hidden md:block" /> */}
                </div>
            </label>
        </div>
    );
};

export default BarberDetailCard;
