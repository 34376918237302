import React from "react";
interface Props {
    data: any;
    sectionIndex: number;
}
const About: React.FC<Props> = ({ data, sectionIndex }) => (
    <>
        <div className="w-full flex items-center mb-4 pt-[60px]">
            <div className="slice-label text-txtcolor">
                <span className="num">{sectionIndex}</span>
                <span className="slice-text">ABOUT</span>
            </div>
        </div>
        {data?.is_image && (
            <div className="h-[250px] md:h-[250px] lg:h-[295px] xl:h-[375px] 2xl:h-[425px] 3xl:h-[480px]">
                <img className="w-full object-cover h-full" src={data.image_url} alt="Barber at work" />
            </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 mt-[30px] gap-[20px]">
            {data?.is_subtitle && <div className="title_text w-full">{data?.subtitle}</div>}
            {data?.is_description && <div className="sub_text w-full tracking-[0.03rem]">{data?.description}</div>}
        </div>
    </>
);

export default About;
