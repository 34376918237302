import React from "react";
import { DefaultImageJPG } from "src/theme/Images";
interface Props {
    data: any;
    sectionIndex: number;
}
const Testimonial: React.FC<Props> = ({ data, sectionIndex }) => {
    type TestimonialsProps = {
        prop: any;
    };

    const Testimonials: React.FC<TestimonialsProps> = ({ prop }) => (
        <>
            <div className="bg-primary text-white p-4">
                {prop.is_description && <p className="mb-4 landing-[26px] text-[20px]">{prop.description}</p>}
                <div className="flex items-center">
                    <img src={prop.image_url || DefaultImageJPG} className="w-12 h-12 mr-4" alt="icon" />
                    <div>
                        <p className="font-semithin landing-[26px] text-[22px] ">{prop.name}</p>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className="slice-label mb-4">
                <span className="num">{sectionIndex}</span>
                {data.is_title && <span className="slice-text">{data.title}</span>}
            </div>
            <div className="mt-8 grid grid-cols-1  gap-4">
                {data?.testimonials?.map((testimonial: any, index: React.Key | null | undefined) => (
                    <Testimonials key={index} prop={testimonial} />
                ))}
            </div>
        </>
    );
};

export default Testimonial;
