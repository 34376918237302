import React, { useState } from "react";
import { DefaultAuthDataInit, initAction, IAuthData, IInitAction } from "./Auth.interface";
import DropdownModal from "../../components/DropdownModal";
import VerifyPassword from "./Password/VerifyPassword";
import PhoneEmail from "./Login/PhoneEmail";
import VerifyOtp from "./Login/VerifyOtp";
import Login from "./Login/Login";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import ForgotPassword from "./Password/ForgotPassword";
import ForgotPasswordVerifyOtp from "./Password/VerifyOtp";
import NewPassword from "./Password/NewPassword";
import Sidebar from "src/components/Sidebar";

const Auth = () => {
    const [action, setAction] = useState(initAction);
    const [authData, setAuthData] = useState<IAuthData>(DefaultAuthDataInit);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const AuthUserState = useAppSelector((data) => data.AuthUser);

    const handleClose = () => {
        dispatch(setIsShowLoginModal(false));
    };
    const handleAuthAction = (type: keyof IInitAction) => {
        setAction(() => {
            const resetActions = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IInitAction] = false;
                return acc;
            }, {} as IInitAction);
            return { ...resetActions, [type]: true };
        });
    };
    const handleVerifyOtpRedirect = () => {
        if (authData.email && authData.phone) {
        } else {
            handleAuthAction("phoneEmailVerification");
        }
    };
    const resetAuthAction = () => {
        setAction(initAction);
        setAuthData(DefaultAuthDataInit);
    };

    return (
        <>
            {!uiState.isMobile && (
                <DropdownModal handleClose={handleClose} size="max-w-md">
                    <div className="py-8 px-6">
                        {!uiState.isMobile &&
                            (action.otp ? (
                                <VerifyOtp
                                    authData={authData}
                                    setAuthData={setAuthData}
                                    handleVerifyOtpRedirect={handleVerifyOtpRedirect}
                                    handleAuthAction={handleAuthAction}
                                    resetAuthAction={resetAuthAction}
                                />
                            ) : action.phoneEmailVerification ? (
                                <PhoneEmail authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} />
                            ) : action.passwordVerification ? (
                                <VerifyPassword authData={authData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                            ) : action.forgotPassword ? (
                                <ForgotPassword authData={authData} handleAuthAction={handleAuthAction} setAuthData={setAuthData} />
                            ) : action.forgotPasswordOtp ? (
                                <ForgotPasswordVerifyOtp
                                    authData={authData}
                                    setAuthData={setAuthData}
                                    resetAuthAction={resetAuthAction}
                                    onCloseModal={handleClose}
                                    handleAuthAction={handleAuthAction}
                                />
                            ) : action.newPassword ? (
                                <NewPassword authData={authData} handleAuthAction={handleAuthAction} />
                            ) : (
                                <Login authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} handleClose={handleClose} />
                            ))}
                    </div>
                </DropdownModal>
            )}
            <Sidebar isOpen={uiState.isMobile && AuthUserState.isShowLoginModal} position="bottom" handleClose={() => handleClose()} customClass={`w-full rounded-t-[24px] ${"h-[80%]"}`} size={""}>
                {action.otp ? (
                    <VerifyOtp authData={authData} setAuthData={setAuthData} handleVerifyOtpRedirect={handleVerifyOtpRedirect} handleAuthAction={handleAuthAction} resetAuthAction={resetAuthAction} />
                ) : action.phoneEmailVerification ? (
                    <PhoneEmail authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} />
                ) : action.passwordVerification ? (
                    <VerifyPassword authData={authData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                ) : action.forgotPassword ? (
                    <ForgotPassword authData={authData} handleAuthAction={handleAuthAction} setAuthData={setAuthData} />
                ) : action.forgotPasswordOtp ? (
                    <ForgotPasswordVerifyOtp authData={authData} setAuthData={setAuthData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                ) : action.newPassword ? (
                    <NewPassword authData={authData} handleAuthAction={handleAuthAction} />
                ) : (
                    <Login authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} handleClose={handleClose} />
                )}
            </Sidebar>
        </>
    );
};

export default Auth;
