import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

interface Props {
    data: any;
}
const Gallery: React.FC<Props> = ({ data }) => (
    <div className="text-txtcolor spacing-top">
        <div className=" w-full ">
            <div className="flex justify-between">
                <div className="flex w-full">
                    <h2 className="custom-heading w-full font-semithin uppercase mb-8">Gallery</h2>
                </div>
            </div>
            <div className="">
                <Swiper
                    slidesPerView={1.2}
                    spaceBetween={20}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    loop={true}
                    navigation={true}
                    style={{
                        height: "",
                    }}
                    className="mySwiper w-full"
                    breakpoints={{
                        // When window width is <= 768px
                        768: {
                            slidesPerView: 3.8,
                        },
                    }}
                >
                    {/* Slides with content */}
                    {data.urls.map((img: any, index: React.Key | null | undefined) => (
                        <SwiperSlide key={index}>
                            <div className="relative w-full h-full cursor-pointer">
                                <img src={`${img}`} alt="Gallery Item 1" className="w-full object-cover aspect-square" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {/* Image 1 */}
            </div>
        </div>
    </div>
);

export default Gallery;
