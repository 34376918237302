import React, { FC, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { usePhone } from "src/hooks/usePhone";
import { IForgotPassword, IForgotPasswordProps, AuthDataInit } from "./Password.interface";
import { useTranslation } from "react-i18next";
import PhoneOrEmail from "src/components/PhoneOrEmail";
import { FaArrowLeftLong } from "react-icons/fa6";
import { errorCode } from "src/constants/common";
import CustomButton from "src/components/CustomButton";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";

const ForgotPassword: FC<IForgotPasswordProps> = ({ setAuthData, authData, handleAuthAction }) => {
    const { t } = useTranslation();
    const { setFormatPhone } = usePhone();
    const [isLoading, setIsLoading] = useState(false);
    const inputType = authData?.email && !authData?.phone ? "phone" : authData?.phone && !authData?.email ? "email" : "both";

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IForgotPassword>({
        defaultValues: {
            emailPhone: "",
        },
    });

    const handleSave = (data: any) => {
        setIsLoading(true);
        if (data.emailPhone === "") {
            setError("emailPhone", {
                type: "manual",
                message: t("Enter phone number or email"),
            });
            setIsLoading(false);

            return;
        }
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            setIsLoading(false);

            return;
        }
        let payload = {};
        if (info.isEmail) {
            payload = {
                email: info.data,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }
        let authDetail = { ...AuthDataInit, isEmail: info.isEmail };
        if (!info.isEmail) {
            const phoneInfo = info.data;
            const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
            const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
            authDetail = {
                ...authDetail,
                phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
            };
        } else {
            authDetail = {
                ...authDetail,
                email: info.data,
            };
        }
        axiosPost(API.USER.FORGOT_PASSWORD, payload)
            .then(() => {
                if (!info.isEmail) {
                    const phoneInfo = info.data;
                    const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                    const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                    authDetail = {
                        ...authDetail,
                        phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                    };
                } else {
                    authDetail = {
                        ...authDetail,
                        email: info.data,
                    };
                }
                setAuthData(authDetail);
                handleAuthAction("forgotPasswordOtp");
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError("emailPhone", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                    } else {
                        Object.keys(response).forEach((field) => {
                            setError("emailPhone", {
                                type: "manual",
                                message: response.message,
                            });
                        });
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="content font-primary w-full">
            <h5 className="login-title-text">{t("Forgot Password?")}</h5>
            <p className="login-subtitle-text ">{t("forgot_password_description")}</p>
            <div className="mt-5">
                <form onSubmit={handleSubmit(handleSave)}>
                    <label htmlFor="phone" className="font-medium text-txtAppointmentColor hover:shadow-none text-sm leading-[18px] mb-2 text-labelTxt">
                        {t("Enter phone number or email")}
                    </label>
                    <Controller
                        name="emailPhone"
                        control={control}
                        render={({ field: { onChange } }: any) => (
                            <div className="flex flex-col mt-[6px]">
                                <PhoneOrEmail onNumberChange={onChange} errors={errors.emailPhone} inputType={inputType} />
                            </div>
                        )}
                    />
                    <div className="w-full flex justify-center">
                        <CustomButton type="button" className="mt-7 !border-0 !bg-transparent text-center ring-0 text-txtAppointmentColor " shadow={false} onClick={() => handleAuthAction("login")}>
                            <FaArrowLeftLong size={14} className="mr-2" /> {t("Back to log in ")}
                        </CustomButton>
                    </div>
                    <div className="mt-[125px]">
                        <CustomButton type="submit" className="w-full mt-6" isLoading={isLoading} disabled={isLoading} primary>
                            <span className="pt-1">{t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
