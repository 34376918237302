import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { usePhone } from "../../../hooks/usePhone";
import { LOCAL_STORAGE_KEYS, errorCode } from "../../../constants/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PhoneOrEmail from "../../../components/PhoneOrEmail";
import { useAppDispatch } from "../../../redux/hooks";
import { IGettingStarted, ILoginProps } from "../Auth.interface";
import { userLogin, userPhoneEmailLogin } from "../../../redux/reducers/Login.slice";
import { trim } from "lodash";
import { AppleSVG, Facebook1SVG, GoogleSVG } from "src/theme/Images";
import { getSlug } from "src/utils/global-functions";
import CustomButton from "src/components/CustomButton";
import { me } from "src/redux/reducers/common/Common.slice";
let newWindow: any;

const Login: FC<IGettingStarted> = ({ authData, setAuthData, handleAuthAction, handleClose }) => {
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { setFormatPhone } = usePhone();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<ILoginProps>({
        defaultValues: {
            emailPhone: "",
        },
    });
    const onInputChange = (data: any) => {
        if (trim(data.data)) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };
    const handlePhoneEmail = async (payload: any) => {
        setIsLoading(true);
        const result: any = await dispatch(userPhoneEmailLogin(payload));
        if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            handleAuthAction("otp");
        }
        if (result.type === userPhoneEmailLogin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError("emailPhone", {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError("emailPhone", {
                        type: "manual",
                        message: response.message,
                    });
                }
                setIsLoading(false);
                return;
            }
            toast.error(response.message);
        }
    };
    const handlePhoneOrEmail = async (payload: any, info: any) => {
        setIsLoading(true);

        const result: any = await dispatch(userLogin(payload));
        if (result.type === userLogin.fulfilled.toString()) {
            let updateAuthData = { ...authData, isEmail: info.isEmail };
            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                updateAuthData = {
                    ...updateAuthData,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                updateAuthData = {
                    ...updateAuthData,
                    email: info.data,
                };
            }
            setAuthData(updateAuthData);

            handleAuthAction("otp");
        }
        if (result.type === userLogin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError("emailPhone", {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError("emailPhone", {
                        type: "manual",
                        message: response.message,
                    });
                }
                setIsLoading(false);

                return;
            }
            toast.error(response.message);
        }
    };

    const handleSave = async (data: any) => {
        setIsLoading(true);
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            setIsLoading(false);
            return;
        }
        let payload = {};

        if (authData.phone && authData.email) {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                email: info.data,
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
            handlePhoneEmail(payload);
        } else {
            if (info.isEmail) {
                payload = {
                    email: info.data,
                };
            } else {
                const mainNumber = `+${info.data.code}${info.data.number}`;
                payload = {
                    phone: mainNumber,
                    phone_country_code: info.data.country,
                };
            }
            handlePhoneOrEmail(payload, info);
        }
    };
    const handleWindowClose = async (message: any) => {
        newWindow.close();
        localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, message);
        await dispatch(me());
        handleClose();
    };

    const handleLogin = (type: "google" | "facebook" | "apple") => () => {
        const url = `${process.env.REACT_APP_SOCIAL_URL}${getSlug()}/${type}`;
        const height = 600;
        const width = 500;
        const leftPosition = window.screen.width / 2 - (width / 2 + 10);
        const topPosition = window.screen.height / 2 - (height / 2 + 50);
        newWindow = window.open(
            url,
            "Window2",
            `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`,
        );
    };
    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.source === newWindow) {
                handleWindowClose(event.data);
            }
        });
        return () => window.removeEventListener("message", handleWindowClose);
    }, []);
    return (
        <div className="content font-primary">
            <h1 className="login-title-text">{t("Getting started")}</h1>
            <p className="login-subtitle-text">What is your phone number or email?</p>
            <div className="mt-3">
                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="emailPhone"
                        control={control}
                        render={({ field: { onChange } }: any) => (
                            <div className="flex flex-col w-full">
                                <PhoneOrEmail
                                    errors={errors.emailPhone}
                                    onNumberChange={(data: any) => {
                                        onChange(data);
                                        onInputChange(data);
                                    }}
                                />
                            </div>
                        )}
                    />
                    <CustomButton primary isLoading={isLoading} disabled={isButtonDisabled || isLoading} className={`w-full mt-5`} type="submit">
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </form>
            </div>
            <div className="flex items-center mt-[30px]">
                <hr className="border-spacing-2 border-borderColorPrimary opacity-20 w-full my-0" />
                <span className="px-4 font-medium font-xs -tracking-[0.12px] text-textGrey ">{t("Or")}</span>
                <hr className="border-spacing-2 border-borderColorPrimary opacity-20 w-full my-0 " />
            </div>
            <div className="text-center mt-[30px]">
                <CustomButton outlineSecondary icon={<GoogleSVG className="me-2 w-5" />} size="w-full" className="!font-medium mt-4" onClick={handleLogin("google")}>
                    {t("Continue with Google")}
                </CustomButton>
                <CustomButton outlineSecondary icon={<Facebook1SVG className="me-2 w-5" />} size="w-full" className="!font-medium mt-4" onClick={handleLogin("facebook")}>
                    {t("Continue with Facebook")}
                </CustomButton>
                <CustomButton outlineSecondary icon={<AppleSVG className="me-2 w-5 text-txtcolor" />} size="w-full" className="!font-medium mt-4" onClick={handleLogin("apple")}>
                    {t("Continue with Apple")}
                </CustomButton>
            </div>
        </div>
    );
};

export default Login;
