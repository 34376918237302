import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ManageSubmit from "./AppointmentPreview/ManageSubmit";
import { BiInfoCircle } from "react-icons/bi";
import { useAppSelector } from "src/redux/hooks";

const NoShowPolicy: FC = () => {
    const { t } = useTranslation();
    const uiStates = useAppSelector((data) => data.UiStates);

    return (
        <div className="flex flex-col font-primary tracking-[0.03rem]">
            <div className="flex flex-col mb-5 mt-1">
                <h3 className={uiStates.isMobile ? "font-medium text-lg text-txtAppointmentColor" : "location-title-text"}>Cancelation & No Show Policy</h3>
                <span className={uiStates.isMobile ? "font-normal text-sm text-textGrey" : "location-sub-text"}>{t("Review our no show and cancelation policies")}.</span>
            </div>
            <div className={`font-normal leading-[1.5em] text-txtAppointmentColor tracking-[0.03rem] ${uiStates.isMobile ? "text-[14px]" : "text-[16px]"}`}>
                Your have time until 12:00 AM on February 24th, 2024 to cancel this appointment without being charged.
            </div>
            <div className="flex w-full gap-3 bg-contentBackground p-2.5 border-0 rounded-secondary  items-center mt-4">
                <BiInfoCircle size={27} className="text-primary" />
                <p className="text-sm  text-primary font-normal tracking-[0.03rem]">You will be charged a up to 50% fee if you cancel your appointment after this date.</p>
            </div>
            <div className="flex w-full gap-3 bg-contentBackground p-2.5 border-0 rounded-secondary  items-center mt-3">
                <BiInfoCircle size={30} className="text-primary" />
                <p className="text-sm  text-primary font-normal tracking-[0.03rem]">You will be charged up to 100% if you do not attend your appointment on the date you specified</p>
            </div>
            <ManageSubmit />
        </div>
    );
};

export default NoShowPolicy;
