import React, { useState } from "react";
import { GoInfo } from "react-icons/go";
import { ISpecialistService } from "../../../../Interface";
import { convertToHumanReadable, getShortName } from "../../../../utils/global-functions";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setSelectedServices, setTempStore } from "../../Booking.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import Sidebar from "src/components/Sidebar";
import ServiceInfo from "src/app/BookAppointment/SelectServices/ServiceInfo";
interface IProps {
    serviceData: ISpecialistService;
    removeable?: boolean;
    isSelected?: boolean;
    handleRemoveService?: () => void;
}

const ServiceCardComponent = ({ serviceData, removeable = false, handleRemoveService, isSelected = false }: IProps) => {
    const { t } = useTranslation();
    const bookingInfo = useAppSelector(getBookingInfo);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const [isShowServiceInfoPopup, setIsShowServiceInfoPopup] = useState(false);
    const handleInfo = () => {
        setIsShowServiceInfoPopup(true);
    };

    // const handleServiceSelect = async (e: any) => {
    //     e.stopPropagation();
    //     if (e.target.id === "alert_circle_svg") {
    //         return;
    //     }
    //     console.log("serviceData", serviceData);

    //     if (bookingInfo.tempStore?.selectedServices?.some((sObj) => sObj.id === serviceData.id || (sObj.parent_id && sObj.parent_id === serviceData.parent_id))) {
    //         const filteredServices = bookingInfo.tempStore?.selectedServices?.filter((serviceObj) => serviceObj?.parent_id !== serviceData?.parent_id && serviceObj.id !== serviceData.id);
    //         await dispatch(setTempStore({ selectedServices: filteredServices }));
    //     } else {
    //         console.log("bookingInfo.tempStore", bookingInfo.tempStore);

    //         await dispatch(
    //             setTempStore({
    //                 selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
    //             }),
    //         );
    //     }
    // };

    const handleServiceSelect = async (e: any) => {
        e.stopPropagation();
        if (e.target.id === "alert_circle_svg") {
            return;
        }

        // if (bookingInfo.tempStore?.selectedServices?.some((sObj) => sObj.id === serviceData.id)) {
        //     const filteredServices = bookingInfo.tempStore?.selectedServices?.filter((serviceObj) => serviceObj.id !== serviceData.id);
        //     // await dispatch(setSelectedServices(filteredServices));
        //     await dispatch(setTempStore({ selectedServices: filteredServices }));
        // } else {
        //     console.log("bookingInfo.tempStore", bookingInfo.tempStore);
        //     if (serviceData?.parent_id && bookingInfo.tempStore?.selectedServices?.some((sObj) => sObj?.parent_id === serviceData?.parent_id)) {
        //         const filteredServices = bookingInfo.tempStore?.selectedServices?.filter((serviceObj) => serviceObj?.parent_id !== serviceData?.parent_id);
        //         await dispatch(setTempStore({ selectedServices: [...filteredServices, { ...serviceData, quantity: 1 }] }));
        //     } else {
        //         await dispatch(
        //             setTempStore({
        //                 selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
        //             }),
        //         );
        //     }
        // }
        if (removeable) {
            handleRemoveService && handleRemoveService();
        } else {
            await dispatch(
                setTempStore({
                    selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
                }),
            );
        }
    };

    return (
        <div className="w-full flex relative">
            <div
                className={`inline-flex items-center justify-between w-full h-[78px] rounded-secondary p-4 pr-6 text-gray-500 hover:border-primary border cursor-pointer group ${
                    isSelected ? "border-primary" : "border-borderColorPrimary"
                } `}
                onClick={handleServiceSelect}
            >
                <div className="flex flex-row justify-between w-full h-full  ">
                    <div className="w-full">
                        <div className="flex gap-[10px] items-center">
                            <h2 className="text-txtcolor font-bold text-sm leading-[140%] pt-0.5  tracking-[0.04rem]">{serviceData.name}</h2>
                            <Tooltip
                                content={
                                    <div>
                                        <p className="text-txtcolor">{serviceData.name}</p>
                                        <p className="text-secondaryTxt font-medium text-xs">{serviceData.description}</p>
                                    </div>
                                }
                                trigger="hover"
                                style={"light" as any}
                                className="w-64 bg-contentBackground"
                                placement="bottom"
                            >
                                <GoInfo id="alert_circle_svg" size={16} className="text-primary" onClick={handleInfo} />
                            </Tooltip>
                        </div>
                        {serviceData?.price && serviceData?.price?.duration && (
                            <div className="text-textGrey mt-1 font-medium text-sm tracking-[0.02rem]">{convertToHumanReadable(serviceData?.price?.duration)}</div>
                        )}
                    </div>
                    {/* <div className="w-full flex">hlw</div> */}
                    {/* <div className={`w-full flex items-center ${serviceData.price?.starting_price ? "justify-between" : "justify-end"}`}>
                        {serviceData.price?.starting_price && <p className="text-secondaryTxt font-medium text-xs leading-[150%] -tracking-[0.12px] mt-1">{t("Starting price")}</p>}
                        <div className="bg-inputbg px-2 text-base font-bold -tracking-[0.16px] rounded-md">{serviceData.price && `$${serviceData.price?.price}`}</div>
                    </div> */}
                    <div className=" flex flex-row gap-10 ">
                        {!uiState.isMobile && (
                            <div className="w-[50%] flex flex-col">
                                <h3 className="text-secondaryTxt font-normal text-xs leading-[150%] mb-1 tracking-[0.02rem]">Specialist</h3>
                                <div className="flex items-center ">
                                    <div className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-2 -gap-[10px] ">
                                        {serviceData?.staff?.map((staff: any) =>
                                            staff?.profile_image_url ? (
                                                <img
                                                    key={staff.id} // Ensure each item in the list has a unique key
                                                    src={staff.profile_image_url} // Use staff.profile_image_url directly if available
                                                    alt="Profile"
                                                    width={48}
                                                    height={48}
                                                    className="w-6 h-6 rounded-primary bg-gray-100 border-2 border-borderColorSecondary"
                                                />
                                            ) : (
                                                <span
                                                    key={staff.id} // Ensure each item in the list has a unique key
                                                    className="w-6 h-6 rounded-primary border-2 border-white flex justify-center items-center bg-gray-100 text-secondaryTxtColor text-xs leading-[18px] font-semibold"
                                                >
                                                    {getShortName(staff?.full_name)}
                                                </span>
                                            ),
                                        )}

                                        {/* <span className="w-6 h-6 rounded-full border-2 border-white flex justify-center items-center bg-gray-100 text-secondaryTxtColor text-xs leading-[18px] font-semibold ">
                                    {getShortName(`${staff.first_name ?? ""} ${staff.last_name ?? ""}`)}
                                </span> */}

                                        {/* <div className="h-6 rounded-full  bg-gray-100 border-2 border-white text-secondaryTxtColor text-xs leading-[18px] font-semibold flex items-center justify-center">
                                {remainingCount === 0 ? 'No team members' : remainingCount > 0 ? `+${remainingCount}` : 'No team members'}
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        {serviceData?.price && (
                            <div className={`w-full flex flex-col `}>
                                <p className=" w-full text-secondaryTxt font-normal text-xs leading-[150%] text-nowrap tracking-[0.02rem]">
                                    {serviceData.price?.starting_price ? t("Starting price") : t(" Price")}
                                </p>
                                <div className=" justify-end flex text-base font-bold pt-1 rounded-secondary">{serviceData.price && `$${serviceData.price?.price}`}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Sidebar
                isOpen={isShowServiceInfoPopup}
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={() => setIsShowServiceInfoPopup(false)}
                size={""}
                customClass={` ${uiState.isMobile ? "w-full rounded-t-[24px] h-[80%]" : "w-[660px]"}`}
            >
                <ServiceInfo data={serviceData} />
            </Sidebar>
        </div>
    );
};

export default ServiceCardComponent;
