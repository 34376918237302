import { createSlice } from "@reduxjs/toolkit";
import { ISpecialist } from "../../Interface";

const initialState = {
    isShowSpecialistProfileModal: false,
    showSpecialistProfileModalData: undefined,
    isMobile: false,
    isMobilePageVisitedOnce: false,
    currentBreakpoint: "2XL",
};

interface IStateType {
    isMobile: boolean;
    isMobilePageVisitedOnce: boolean;
    isShowSpecialistProfileModal: boolean;
    showSpecialistProfileModalData: ISpecialist | undefined;
    currentBreakpoint: "LG" | "XL" | "2XL";
}

export const uiSlice = createSlice({
    name: "ui",
    initialState: initialState as IStateType,
    reducers: {
        setIsMobilePageVisitedOnce: (state, { payload }) => {
            state.isMobilePageVisitedOnce = payload;
        },
        setIsMobile: (state, { payload }) => {
            state.isMobile = payload;
        },
        setIsShowSpecialistProfileModal: (state, { payload }) => {
            state.isShowSpecialistProfileModal = payload;
        },
        setShowSpecialistProfileModalData: (state, { payload }) => {
            state.showSpecialistProfileModalData = payload;
        },
        setCurrentBreakpoint: (state, { payload }) => {
            state.currentBreakpoint = payload;
        },
    },
});

export const { setIsMobile, setIsMobilePageVisitedOnce, setShowSpecialistProfileModalData, setIsShowSpecialistProfileModal, setCurrentBreakpoint } = uiSlice.actions;

export default uiSlice.reducer;
