import React, { useEffect, useState } from "react";
import SpecialistDetail from "../../../components/SpecialistDetail";
import InputWithLabel from "../../../components/InputWithLabel";
import { IoClose, IoSearchOutline } from "react-icons/io5";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { errorCode } from "../../../constants/common";
import { toast } from "react-toastify";
import { ISpecialistRoleCategory, ISpecialist, ISpecialistService } from "../../../Interface";
import SpecialistCardComponent from "./SpecialistCardComponent";
import { getBookingInfo, setStep, setTempStore } from "../Booking.slice";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../redux/reducers/Ui.slice";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../bookAppointment.interface";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import Sidebar from "src/components/Sidebar";
import { BiSearch } from "react-icons/bi";
import { ArrowLeft, XClose } from "@untitled-ui/icons-react/build/cjs";

const SelectSpecialists = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiStates = useAppSelector((data) => data.UiStates);

    // data states
    const [allSpecialists, setAllSpecialists] = useState<ISpecialist[]>([]);
    const [filteredSpecialists, setFilteredSpecialists] = useState<ISpecialist[]>([]);
    const [staffRoleLists, setStaffRoleLists] = useState<ISpecialistRoleCategory[]>([]);

    // loader states
    const [isShopLocationSpecialistLoading, setIsShopLocationSpecialistLoading] = useState(false);
    const [isShopLocationCategoryLoading, setIsShopLocationCategoryLoading] = useState(false);
    const [isShowSearchBar, setIsShowSearchBar] = useState(false);

    // filter states
    const [categoryFilterValue, setCategoryFilterValue] = useState<any | null>(null);
    const [searchFilterValue, setSearchFilterValue] = useState<string>("");
    const handleStep = async (step: EBookingTabState) => {
        await dispatch(setStep(step));
    };
    useEffect(() => {
        dispatch(setTempStore({ selectedSpecialist: bookingState.selectedSpecialist }));
        loadSpecialists();
        loadCategories();
    }, []);

    useEffect(() => {
        const filteredRecords = allSpecialists.filter((specialistRecord: ISpecialist) => {
            let searchMatched = true;
            let categoryMatched = true;

            // search value matching
            if (searchFilterValue !== "") {
                const specialistFullName = `${specialistRecord.first_name} ${specialistRecord.last_name}`.toLocaleLowerCase();
                searchMatched = specialistFullName.includes(searchFilterValue.toLocaleLowerCase());
            }

            // category filtering
            if (categoryFilterValue) {
                categoryMatched = specialistRecord.staff_role.id === categoryFilterValue;
            }
            return searchMatched && categoryMatched;
        });

        setFilteredSpecialists(filteredRecords);
    }, [categoryFilterValue, searchFilterValue]);

    const loadSpecialists = async () => {
        try {
            setIsShopLocationSpecialistLoading(true);
            const payloadServiceIds = bookingState.selectedServices?.map((service: ISpecialistService) => service.id);
            const payloadData = {
                service_id: payloadServiceIds.length ? payloadServiceIds : undefined,
                booking_date: bookingState.selectedBookingDate,
                booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
            };
            const payloadObj = {
                shop_id: bookingState.selectedShopLocation?.shop_id,
                location_id: bookingState.selectedShopLocation?.id,
            };
            const response = await axiosPost(API.STAFF.LIST, payloadData, payloadObj);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setAllSpecialists(response.data.data);
                setFilteredSpecialists(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            setIsShopLocationSpecialistLoading(false);
        }
    };

    const loadCategories = async () => {
        try {
            setIsShopLocationCategoryLoading(true);
            const urlParamObj = {
                shop_id: bookingState.selectedShopLocation?.shop_id,
                location_id: bookingState.selectedShopLocation?.id,
            };
            const response = await axiosGet(API.STAFF_ROLE.LIST, {}, urlParamObj);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setStaffRoleLists(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            setIsShopLocationCategoryLoading(false);
        }
    };

    const handleBack = () => {
        handleStep(EBookingTabState.TAB_MENU);
        dispatch(setTempStore({ selectedSpecialist: undefined }));
    };
    const handleSearchBar = () => {
        setIsShowSearchBar(!isShowSearchBar);
    };
    return (
        <>
            <div className="flex shrink-0 flex-col">
                <div className="flex flex-row w-full justify-between items-center mt-4 lg:mt-0">
                    <div className="title text-txtcolor font-bold text-2xl flex gap-3 items-center w-full">
                        <div
                            className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton"
                            onClick={handleBack}
                        >
                            <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                        </div>
                        <span className="location-title-text text-[24px]"> {t("Select specialist")}</span>
                        {(isShowSearchBar && uiStates?.isMobile) || !uiStates?.isMobile ? (
                            <div className="w-[calc(100%-83px)] transition delay-150 duration-300 ease-in-out  bg-cardBackground md:mt-0 absolute left-[38px] sm:hidden">
                                <InputWithLabel
                                    buttonIcon={<IoSearchOutline color="#8C9CBB" size={18} className="mr-1 " />}
                                    handlerIconClick={() => setSearchFilterValue("")}
                                    type="text"
                                    inputClass="pl-[41px] !pt-[0px] !pr-[40px]"
                                    placeholder={t("Search")}
                                    // borderClass="hidden lg:block"
                                    handleChange={(e) => setSearchFilterValue(e.target.value)}
                                    value={searchFilterValue}
                                    name="firstname"
                                    buttonIconRight={searchFilterValue ? <XClose className="text-secondaryTxt w-5 mr-3" /> : <span></span>}
                                    disabled={isShopLocationSpecialistLoading}
                                />
                            </div>
                        ) : null}
                    </div>

                    {uiStates.isMobile && (
                        <div className="min-w-[36px] h-[36px] flex border border-borderColorPrimary  items-center justify-center rounded-lg sm:hidden" onClick={handleSearchBar}>
                            {/* <BiSearch className="w-[20px] h-[20px]  text-textGrey" /> */}
                            {/* <IoClose className="w-[20px] h-[20px]  text-textGrey" /> */}
                            {isShowSearchBar ? <IoClose className="w-[20px] h-[20px] text-textGrey" /> : <BiSearch className="w-[20px] h-[20px]  text-textGrey" />}
                        </div>
                    )}

                    <div className="sm:w-[320px] w-full mt-2 md:mt-0 hidden sm:block">
                        <InputWithLabel
                            buttonIcon={<IoSearchOutline color="#8C9CBB" size={18} className="mr-1 " />}
                            handlerIconClick={() => setSearchFilterValue("")}
                            type="text"
                            inputClass="pl-[41px] !pt-[2px] !pr-[40px]"
                            placeholder={t("Search")}
                            // borderClass="hidden lg:block"
                            handleChange={(e) => setSearchFilterValue(e.target.value)}
                            value={searchFilterValue}
                            name="firstname"
                            buttonIconRight={searchFilterValue ? <XClose className="text-secondaryTxt w-[18px] absolute top-[7px] right-3" /> : <span></span>}
                            disabled={isShopLocationSpecialistLoading}
                        />
                    </div>
                </div>
                {!isShopLocationCategoryLoading && (
                    <div className="fl-tab-btn-view flex flex-wrap w-full mt-5 lg:mt-7 mb-4 lg:mb-5">
                        <button
                            onClick={() => setCategoryFilterValue(null)}
                            type="button"
                            className={`fl-tab-link pt-1.5 border-headerBorderColor text-textGrey tracking-[0.03rem] ${categoryFilterValue === null ? "active" : ""}`}
                        >
                            All
                        </button>

                        {staffRoleLists.map((staffRoleList) => (
                            <button
                                key={staffRoleList.id}
                                type="button"
                                className={` fl-tab-link pt-1.5 border-headerBorderColor text-textGrey tracking-[0.03rem] ${categoryFilterValue === staffRoleList.id ? "active" : ""}`}
                                onClick={() => setCategoryFilterValue(staffRoleList.id)}
                            >
                                {staffRoleList.name}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            {/*
                <div className="flex flex-wrap">
                    <div className="mt-3 w-full md:w-1/2 md:pl-5">
                        <SelectBox
                            disabled={isShopLocationSpecialistLoading || isShopLocationCategoryLoading}
                            placeholder={t("Select a category")}
                            name="role"
                            allowClear={true}
                            options={staffRoleLists.map((staffRoleList) => ({
                                label: staffRoleList.name,
                                value: staffRoleList.id,
                            }))}
                            onChange={(option) => setCategoryFilterValue(option)}
                            value={categoryFilterValue}
                        />
                    </div>
                </div> */}

            <div className="overflow-y-scroll grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 scrollbar-hide pb-[71px] md:pb-0 tracking-[0.03rem]">
                {filteredSpecialists.map((specialistData: ISpecialist) => (
                    <SpecialistCardComponent key={specialistData.id} specialistData={specialistData} />
                ))}
            </div>
            <div>
                {filteredSpecialists.length <= 0 && !isShopLocationSpecialistLoading && (
                    <div className=" justify-center flex items-center ">
                        <h1 className="text-txtcolor font-bold  text-base text-textGrey tracking-[0.03rem]">{t("No available specialists")}</h1>
                    </div>
                )}
            </div>

            {/* Show specialist detail component here */}
            {/* <AnimatePresence initial={false} onExitComplete={() => dispatch(setShowSpecialistProfileModalData(undefined))}>
                    {uiStates.isShowSpecialistProfileModal && (
                        <div className="fixed flex items-end lg:absolute lg:hidden bottom-0 top-0 right-0 left-0 bg-[rgba(26,_29,_37,_0.3)] h-full z-30 w-full md:py-4">
                            <SpecialistDetail />
                        </div>
                    )}
                </AnimatePresence> */}
            <Sidebar
                isOpen={uiStates.isShowSpecialistProfileModal}
                position={uiStates.isMobile ? "bottom" : "right"}
                handleClose={() => {
                    dispatch(setIsShowSpecialistProfileModal(false));
                    dispatch(setShowSpecialistProfileModalData(undefined));
                }}
                size="w-[660px]"
            >
                <SpecialistDetail />
            </Sidebar>
            <div className={`absolute bottom-0 w-full bg-contentBackground lg:bg-transparent `}>{uiStates.isMobile && <ManageSubmit />}</div>

            {/* <div className="flex fixed md:absolute justify-center bottom-5 lg:bottom-0 w-full left-0 right-0 z-30 gap-5 px-[30px] 2xl:px-10 ">
                {selectedSpecialistData && !bookingState.isModifyingAppointment && (
                    <>
                        <ButtonCmp onClick={handleSelectSpecialist} className="btn_primary fl-btn w-full lg:w-1/2" loading={isSubmitSpecialistLoading}>
                            {t("Continue")}
                        </ButtonCmp>
                    </>
                )}
            </div> */}
        </>
    );
};

export default SelectSpecialists;
