import React from "react";

const PageNotFound = () => (
    <div className="w-full h-full flex justify-center items-center flex-col">
        <div className="text-3xl m-2">Page Not Found</div>
        <div className="text-lg">The requested page could not be found. Please check the URL and try again.</div>
    </div>
);

export default PageNotFound;
