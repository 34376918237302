import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ISpecialistService } from "../../../../Interface";
import { getBookingInfo, setSelectedServices, setTempStore } from "../../Booking.slice";
import { calculateTotalTaxes } from "../../../../utils/global-functions";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../../bookAppointment.interface";

const ServicesBasket = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const [bookingServices, setBookingServices] = useState<ISpecialistService[]>([]);

    const [servicesSubTotalPrice, setServicesSubTotalPrice] = useState("");
    const [servicesSubTotalTax, setServicesSubTotalTax] = useState("0.00");

    useEffect(() => {
        let totalPrices = 0;
        if (bookingState.step === EBookingTabState.SELECT_SERVICE) {
            totalPrices = bookingState.tempStore.selectedServices.reduce((prevPrice, serviceRecord) => {
                if (serviceRecord.price && serviceRecord.quantity) {
                    return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
                }
                return prevPrice;
            }, 0);
            setBookingServices(bookingState.tempStore.selectedServices);
        } else {
            totalPrices = bookingState.selectedServices.reduce((prevPrice, serviceRecord) => {
                if (serviceRecord.price && serviceRecord.quantity) {
                    return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
                }
                return prevPrice;
            }, 0);
            setBookingServices(bookingState.selectedServices);
        }

        setServicesSubTotalPrice(totalPrices.toFixed(2));
        if (totalPrices && bookingState.selectedShopLocation?.sales_tax) {
            const subTotalTax = calculateTotalTaxes(
                totalPrices.toFixed(2),
                Number(bookingState.selectedShopLocation?.sales_tax?.gst),
                Number(bookingState.selectedShopLocation?.sales_tax?.hst),
                Number(bookingState.selectedShopLocation?.sales_tax?.pst),
            );
            setServicesSubTotalTax(subTotalTax);
        } else {
            setServicesSubTotalTax("0.00");
        }
    }, [bookingState.selectedServices, bookingState.tempStore.selectedServices]);

    const decreaseServiceQuantity = (serviceId: number) => {
        const servicesWithDecreasedServiceQuantity = bookingServices
            .map((serviceRecord) => {
                if (serviceRecord.id === serviceId && serviceRecord.quantity === 1) {
                    return false;
                }
                if (serviceRecord.id === serviceId && serviceRecord.quantity) {
                    return {
                        ...serviceRecord,
                        quantity: serviceRecord.quantity - 1,
                    };
                }
                return serviceRecord;
            })
            .filter((d) => d);
        if (bookingState.step === EBookingTabState.SELECT_SERVICE) {
            dispatch(setTempStore({ selectedServices: servicesWithDecreasedServiceQuantity }));
        } else {
            dispatch(setSelectedServices(servicesWithDecreasedServiceQuantity));
        }
    };

    const increseServiceQuantity = (serviceId: number) => {
        const servicesWithIncresedServiceQuantity = bookingServices.map((serviceRecord) => {
            if (serviceRecord.id === serviceId && serviceRecord.quantity) {
                return {
                    ...serviceRecord,
                    quantity: serviceRecord.quantity + 1,
                };
            }
            return serviceRecord;
        });
        if (bookingState.step === EBookingTabState.SELECT_SERVICE) {
            dispatch(setTempStore({ selectedServices: servicesWithIncresedServiceQuantity }));
        } else {
            dispatch(setSelectedServices(servicesWithIncresedServiceQuantity));
        }
    };

    return (
        <>
            <div className="lg:mt-4 flex flex-col gap-3 mt-3">
                {bookingServices.length > 0 && (
                    <>
                        <h2 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium   text-textGrey tracking-[0.03rem]">Service</h2>
                        {bookingServices.map((selectedService: ISpecialistService, index: number) => (
                            <div className="flex justify-between items-center" key={index}>
                                <div className="flex gap-2 items-center w-1/2">
                                    <div className="bg-dropdownBackgroundColor w-[25px] h-[25px] flex justify-center items-center rounded-primary text-[14px] text-txtAppointmentColor">
                                        <div className="pt-[2px]">{selectedService.quantity}</div>
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3  xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium  tracking-[0.04rem]">
                                        <span>{selectedService.name}</span>
                                    </p>
                                </div>
                                <div className="flex gap-4 items-center w-1/2 justify-end text-txtAppointmentColor tracking-[0.03rem]">
                                    {bookingState.step === EBookingTabState.FINALIZE_BOOKING ? (
                                        <div>{selectedService.quantity} x </div>
                                    ) : (
                                        // <div className="flex gap-5 items-center w-1/2">
                                        //     <span
                                        //         className="border border-primary flex justify-center items-center text-primary h-[25px] w-[25px] rounded-md cursor-pointer"
                                        //         onClick={() => decreaseServiceQuantity(selectedService.id)}
                                        //     >
                                        //         <FaMinus size={12} />
                                        //     </span>
                                        //     <span className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium  ">
                                        //         {selectedService.quantity}
                                        //     </span>
                                        //     <span
                                        //         className="border border-primary flex text-primary justify-center items-center h-[25px] w-[25px] rounded-md cursor-pointer"
                                        //         onClick={() => increseServiceQuantity(selectedService.id)}
                                        //     >
                                        //         <FaPlus size={12} />
                                        //     </span>
                                        // </div>
                                        <></>
                                    )}
                                    <span className="text-txtcolor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium   w-1/3 text-right tracking-[0.03rem]">
                                        {selectedService.price && `$${selectedService.price?.price}`}
                                    </span>
                                    {/* </p> */}
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

export default ServicesBasket;
