import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { convertToHumanReadable, getShortName } from "src/utils/global-functions";

interface ILanding {
    data: any;
}

const ServiceInfo = ({ data }: ILanding) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col font-primary tracking-[0.03rem]">
            <div className="flex flex-col">
                <div className="text-base font-medium text-txtAppointmentColor">{data?.name}</div>
                {data?.price && <div className="text-sm font-normal text-textGrey">{convertToHumanReadable(data?.price?.duration)}</div>}
            </div>
            <div className="flex flex-col mt-4">
                <div className="font-medium text-xs text-txtAppointmentColor tracking-[0.04rem]">Description</div>
                <div className="font-normal text-xs text-txtAppointmentColor tracking-[0.04rem]">{data?.description}</div>
            </div>
            {data?.price && (
                <div className="flex flex-row mt-[18px] justify-between items-center w-full">
                    <div className="font-normal text-sm text-textGrey">{t("Starting price")}</div>
                    <div className="bg-cardBackground border rounded-secondary w-auto flex justify-center px-2.5 h-[24px]">
                        <div className="text-[16px] text-txtAppointmentColor font-bold items-center flex flex-row gap-0.5 h-[24px]">
                            <span className="text-[13px] flex">$</span> <span className="text-[15px] pt-[1px] flex">{data?.price?.price}</span>
                        </div>
                    </div>
                </div>
            )}
            <div className="border-b border-borderColorSecondary py-4"></div>
            <div className="flex flex-col mt-4">
                <div className="text-base font-medium text-txtAppointmentColor">Specialist offering {data?.name}</div>
                <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-4">
                    {data?.staff?.map((staff: any) => (
                        <div className="flex w-[161px] h-auto sm:w-[140px] sm:h-auto" key={staff.id}>
                            <div className="border p-[12px] rounded-secondary h-full bg-contentBackground  border-borderColorSecondary">
                                <div className="mt-0">
                                    {staff?.profile_image_url ? (
                                        <img key={staff.id} src={staff.profile_image_url} alt="Profile" className="w-[161px] h-[118px] sm:w-[114px] rounded-secondary sm:h-[114px]   object-cover" />
                                    ) : (
                                        <span
                                            key={staff.id}
                                            className=" border-2 border-white flex justify-center items-center bg-gray-100 text-secondaryTxtColor text-xs leading-[18px] font-semibold w-[161px] h-[118px] sm:w-[114px] rounded-[6px] sm:h-[114px]   object-cover"
                                        >
                                            {getShortName(staff?.full_name)}
                                        </span>
                                    )}
                                </div>
                                <div className="flex items-end flex-row mt-4 ">
                                    <div className="flex flex-col justify-between flex-1">
                                        <div className="  text-sm font-bold text-txtcolor leading-[140%] tracking-[0.03rem]">{staff?.full_name}</div>
                                        <div className="text-textGrey font-normal pt-1 text-xs tracking-[0.03rem]">{staff?.staff_role?.name}</div>
                                    </div>
                                    <BiInfoCircle size={16} className="text-primary" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceInfo;
