import React, { useEffect, useState } from "react";
import { BellSVG, DotedSVG } from "src/theme/Images";
import { Dropdown, Tooltip } from "flowbite-react";
import { GoInfo } from "react-icons/go";
import { LuBookLock, LuCreditCard, LuLogOut, LuUser } from "react-icons/lu";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useAppSelector } from "../../redux/hooks";
import { axiosGet, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { getCardTypeImage, getShortName } from "src/utils/global-functions";
import CustomButton from "src/components/CustomButton";
import BookingCard from "./Booking/BookingCard";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import { useTranslation } from "react-i18next";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { usePhone } from "src/hooks/usePhone";
import { CountryCode } from "libphonenumber-js";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { initAction } from "./Profile.interface";
import Sidebar from "src/components/Sidebar";
import BookingView from "./Booking/BookingView";

interface ILanding {
    handleAction: (type: string) => () => void;
    handleClose: () => void;
}

const Landing = ({ handleAction, handleClose }: ILanding) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const { getFormatPhone } = usePhone();
    const [isStripeCardLoading, setIsStripeCardLoading] = useState(false);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [stripeCard, setStripeCard] = useState<any>(null);
    const [booking, setBooking] = useState<IAppointmentBooking | null>(null);
    const [referralConfig, setReferralConfig] = useState({ refferer: 0, refferer_type: "percentage" });
    const [action, setAction] = useState(initAction);
    const [isRefresh, setIsRefresh] = useState(true);

    useEffect(() => {
        listStripeCards();
    }, []);

    useEffect(() => {
        if (isRefresh) {
            listBookings();
        }
    }, [isRefresh]);

    useEffect(() => {
        const filterShopSetting = shop.shop_settings.find((setting: { type: string }) => setting.type === "referral_config");
        if (filterShopSetting) {
            setReferralConfig({
                refferer: filterShopSetting.value?.refferer || 0,
                refferer_type: filterShopSetting.value?.refferer_type || "",
            });
        }
    }, [shop]);

    const listStripeCards = () => {
        setIsStripeCardLoading(true);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                const cards = response.data.data.cards;
                const defaultCard = response.data.data.default_card_id;
                if (cards.length) {
                    setStripeCard(cards.find((card: any) => card.id === defaultCard));
                }
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    const listBookings = () => {
        setIsBookingLoading(true);
        const payload = { skip: 0, type: "upcoming" };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payload, params)
            .then((response) => {
                setBooking(response.data.data[0]);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsBookingLoading(false);
                setIsRefresh(false);
            });
    };

    const navigateLocation = () => {
        handleClose();
        navigate(ROUTES.LOCATION);
    };

    const handleActions = (type: string, data: IAppointmentBooking) => (event: any) => {
        event.stopPropagation();
        setBooking(data);
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleActionClose = () => {
        setAction(initAction);
    };

    return (
        <div className="flex flex-col">
            <div className="flex justify-between items-center mb-5">
                <div className="flex space-x-4">
                    {user?.profile_image_url ? (
                        <img
                            src={user?.profile_image_url}
                            alt=""
                            className="rounded-primary bg-blue-100 text-primaryAppointment flex justify-center items-center border-[3px] border-white  object-cover font-bold h-[80px] w-[80px] text-[32px] shadow-custom"
                        />
                    ) : (
                        <div className="rounded-primary bg-blue-100 text-[#4b4b37] shadow-[0px 0px 8px 2px rgba(170, 170, 170, 0.15)] flex justify-center items-center border-[3px] border-white  font-bold h-[80px] w-[80px] text-[32px]">
                            {getShortName(user?.full_name as string)}
                        </div>
                    )}
                </div>
                <div className="flex space-x-[8px] ">
                    <button
                        type="button"
                        className="w-[40px] h-[40px] bg-contentBackground border border-borderColorPrimary rounded-primary flex items-center justify-center"
                        onClick={handleAction("notification")}
                    >
                        <img src={BellSVG} className="w-[20px] h-[20px]" alt="Bell Icon" />
                    </button>

                    <Dropdown
                        className="z-10 bg-dropdownBackgroundColor border border-borderColorPrimary rounded-secondary flex items-center justify-center"
                        theme={{
                            arrowIcon: "ml-2 h-4 w-4",
                            content: "py-1 focus:outline-none  shadow-lg ",
                            // auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
                        }}
                        label=""
                        placement="bottom-start"
                        dismissOnClick={false}
                        renderTrigger={() => (
                            <button type="button" className="w-[40px] h-[40px] bg-contentBackground border border-borderColorPrimary rounded-primary flex items-center justify-center">
                                <img src={DotedSVG} className="w-[20px] h-[20px]" alt="Bell Icon" />
                            </button>
                        )}
                    >
                        <Dropdown.Item
                            icon={LuUser}
                            onClick={handleAction("account_setting")}
                            theme={{
                                container: "right-0",
                                icon: "text-txtcolor mr-3 h-4 w-4 ",
                                base: " flex items-center justify-start py-2 px-4 text-sm  text-txtcolor cursor-pointer w-full hover:bg-contentBackground hover:rounded-secondary focus:bg-contentBackground  focus:outline-none ",
                            }}
                        >
                            Account Settings
                        </Dropdown.Item>
                        <Dropdown.Item
                            icon={LuBookLock}
                            onClick={handleAction("appointment_history")}
                            theme={{
                                container: "right-0",
                                icon: "text-txtcolor mr-3 h-4 w-4 ",
                                base: " flex items-center justify-start py-2 px-4 text-sm  text-txtcolor cursor-pointer w-full hover:bg-contentBackground hover:rounded-secondary focus:bg-contentBackground  focus:outline-none ",
                            }}
                        >
                            Appointment History
                        </Dropdown.Item>

                        <Dropdown.Divider className="my-1 h-px bg-contentBackground" />

                        <Dropdown.Item
                            icon={IoMdNotificationsOutline}
                            onClick={handleAction("notification_setting")}
                            theme={{
                                container: "right-0",
                                icon: "text-txtcolor mr-3 h-4 w-4 ",
                                base: " flex items-center justify-start py-2 px-4 text-sm  text-txtcolor cursor-pointer w-full hover:bg-contentBackground hover:rounded-secondary focus:bg-contentBackground  focus:outline-none ",
                            }}
                        >
                            Notification Settings
                        </Dropdown.Item>
                        <Dropdown.Item
                            icon={LuCreditCard}
                            onClick={handleAction("payment_method")}
                            theme={{
                                container: "right-0",
                                icon: "text-txtcolor mr-3 h-4 w-4 ",
                                base: " flex items-center justify-start py-2 px-4 text-sm  text-txtcolor cursor-pointer w-full hover:bg-contentBackground hover:rounded-secondary focus:bg-contentBackground  focus:outline-none ",
                            }}
                        >
                            Payment methods
                        </Dropdown.Item>
                        {/* <Dropdown.Item icon={LuKeyRound} theme={{ icon: "text-textGrey mr-3 h-4 w-4" }}>
                            Change Password
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item icon={LuGlobe} theme={{ icon: "text-textGrey mr-3 h-4 w-4" }}>
                            Language & Region
                        </Dropdown.Item> */}
                        <Dropdown.Divider className="my-1 h-px bg-contentBackground" />
                        <Dropdown.Item
                            icon={LuLogOut}
                            className="text-danger"
                            onClick={handleAction("logout")}
                            theme={{
                                container: "right-0",
                                icon: "text-danger mr-3 h-4 w-4 ",
                                base: " flex items-center justify-start py-2 px-4 text-sm  text-txtcolor cursor-pointer w-full hover:bg-contentBackground hover:rounded-secondary focus:bg-contentBackground  focus:outline-none ",
                            }}
                        >
                            Log out
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
            <div className="flex justify-between mb-6">
                <div className="flex flex-col">
                    <h1 className=" text-lg font-semibold text-txtAppointmentColor capitalize">{user?.full_name}</h1>
                    {user?.phone && <h3 className="text-xs text-txtAppointmentColor font-normal">{getFormatPhone(user?.phone as string, user?.phone_country_code as CountryCode)}</h3>}
                </div>
                <div className="flex gap-2 items-center">
                    <span className="ml-1 flex items-center">
                        <span className="text-sm font-normal text-txtAppointmentColor ">{user?.total_loyalty_points} Points</span>
                    </span>
                    <Tooltip
                        content={
                            <div className="items-center">
                                <p className="text-txtcolor">Refferal points</p>
                            </div>
                        }
                        trigger="hover"
                        style={"light" as any}
                        className="w-64 bg-contentBackground"
                        placement="bottom"
                    >
                        <GoInfo id="alert_circle_svg" size={16} className="text-textGrey mt-[2px]" />
                    </Tooltip>
                </div>
            </div>
            <div className="mb-6 flex flex-col">
                <div className="flex space-x-[4px] items-center mb-1.5 gap-1.5">
                    <span className="text-sm font-bold text-txtAppointmentColor">Refferal code</span>
                    <Tooltip
                        content={
                            <div className="items-center">
                                <p className="text-txtcolor">
                                    {t("Receive 0% off any service when you refer a friend!", {
                                        value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                    })}
                                </p>
                            </div>
                        }
                        trigger="hover"
                        style={"light" as any}
                        className="w-64 bg-contentBackground"
                        placement="right"
                    >
                        <GoInfo id="alert_circle_svg" size={16} className="text-secondaryTxt mb-1" />
                    </Tooltip>
                </div>
                <div className="flex bg-contentBackground rounded-secondary border border-borderColorPrimary text-textGrey leading-[44px] text-base font-normal items-center pl-3 ">
                    {user?.referral_code}
                </div>
            </div>
            <div className="mb-6 flex flex-col">
                <div className="flex justify-between items-center mb-1">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">Appointments</h2>
                    <button className="text-sm font-medium text-primaryAppointment" onClick={navigateLocation}>
                        + New appointment
                    </button>
                </div>
                {booking ? (
                    <div className="cursor-pointer" onClick={handleActions("booking", booking)}>
                        <BookingCard booking={booking} handleAction={handleActions} />
                    </div>
                ) : (
                    <div className="w-full border border-borderColorPrimary px-[12px] py-[18px] flex flex-col items-center h-[96px] bg-contentBackground rounded-secondary justify-center">
                        {isBookingLoading ? (
                            <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                        ) : (
                            <>
                                <p className="text-sm text-txtAppointmentColor font-medium leading-[18px]">You have never made an appointment</p>
                                <CustomButton outlineSecondary onClick={navigateLocation} className="!h-[36px] rounded-lg text-primaryAppointment border-primaryAppointment mt-1.5">
                                    Book Now
                                </CustomButton>
                            </>
                        )}
                    </div>
                )}
            </div>
            {/* <div className="mb-6 flex flex-col">
                <div className="flex justify-between items-center mb-2.5">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">Appointments</h2>
                    <button className="text-sm font-medium text-primaryAppointment">+ New appointment</button>
                </div>
                <div className="p-3 border rounded-lg border-lightgray">
                    <div className="flex items-start ">
                        <img src="https://placehold.co/40x40" alt="Person" className="mr-4 w-10 h-10 object-cover rounded-lg" />
                        <div className=" items-center">
                            <h3 className="text-sm font-medium text-txtAppointmentColor leading-[18.2px] pt-[2px]">Harbord Barbershop</h3>
                            <p className="text-xs text-textGrey font-normal leading-[18px]">307 Jesse Centers, Hellerburgh</p>
                        </div>
                        <button className="text-xs text-primaryAppointment font-medium text-right flex-1">Add Tip</button>
                    </div>
                    <div className="mt-2 flex  gap-2">
                        <span className="pl-[55px] text-xs text-txtAppointmentColor font-normal">
                            <span> October 22nd, 2024 at 4:45pm</span>
                        </span>
                    </div>
                </div>
            </div> */}
            {/* <div className="w-full border border-line " */}
            {/* <div className="mb-6">
                <div className="flex justify-between items-center mb-3">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">Consultations</h2>
                    <button className="text-sm font-medium text-primaryAppointment">Open Consultation</button>
                </div>
                <div className="p-3 rounded-lg border-lightgray border flex items-start">
                    <img src="https://placehold.co/40x40" alt="Person" className="mr-4 w-10 h-10 object-cover rounded-lg" />
                    <div>
                        <h4 className="text-sm font-medium text-txtAppointmentColor leading-[18.2px] pt-[2px]">Tattoo Consultation</h4>
                        <span className="text-xs text-primaryAppointment">View details • Confirm</span>
                    </div>
                    <p className="text-xs text-secondaryTxt text-right flex-1">ID #67251</p>
                </div>
            </div> */}
            {/* <div className="mb-6 flex flex-col">
                <div className="flex justify-between items-center mb-2.5">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">Appointments</h2>
                    <button className="text-sm font-medium text-primaryAppointment">+ New appointment</button>
                </div>
                <div className="w-full border border-line px-[12px] py-[18px] flex flex-col items-center h-[96px] bg-white rounded-lg justify-center">
                    <p className="text-sm text-txtAppointmentColor font-medium leading-[18px]">You have never made an appointment</p>
                    <CustomButton outlineSecondary className="!h-[36px] rounded-lg text-primaryAppointment border-primaryAppointment mt-1.5">
                        Book Now
                    </CustomButton>
                </div>
            </div> */}
            <div className="mb-6">
                <div className="flex justify-between items-center mb-1.5">
                    <h2 className="text-sm font-bold text-txtAppointmentColor leading-[18.2px]">Card on file</h2>
                    <button className="text-sm font-medium text-primaryAppointment cursor-pointer tracking-[0.03rem]" onClick={handleAction("payment_method")}>
                        + Add payment method
                    </button>
                </div>
                {stripeCard ? (
                    <div className="p-3 rounded-secondary border border-borderColorPrimary flex items-start">
                        <img src={getCardTypeImage(stripeCard.card.brand)} alt="Person" className="mr-4 rounded-lg w-[40px] h-[40px] object-contain" />
                        <div>
                            <h4 className="text-sm font-medium text-txtAppointmentColor leading-[18.2px]">
                                {stripeCard.card.brand} card ending in {stripeCard.card.last4}
                            </h4>
                            <span className="text-xs text-primaryAppointment">Default</span>
                        </div>
                    </div>
                ) : (
                    <div className="bg-contentBackground border border-borderColorPrimary py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-xl relative cursor-pointer mt-3 h-[66px]">
                        <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{isStripeCardLoading ? t("Loading") : t("Card no available")}</p>
                    </div>
                )}
            </div>
            <Sidebar
                isBack
                isOpen={action.booking}
                isBottomView={uiState.isMobile}
                size="w-[460px]"
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={handleActionClose}
                handleBack={handleActionClose}
            >
                {action.booking && booking && <BookingView booking={booking} handleClose={handleActionClose} setIsRefresh={setIsRefresh} />}
            </Sidebar>
        </div>
    );
};

export default Landing;
