import { createSlice } from "@reduxjs/toolkit";
import { IShopLocation, ISpecialist, ISpecialistService } from "../../Interface";
import { EBookingTabState } from "src/app/BookAppointment/bookAppointment.interface";
import { RootState } from "src/redux/store";

const initialState = {
    modifyingAppointmentId: undefined,
    isModifyingAppointment: false,
    isShowFinalizeBooking: false,
    selectedShopLocation: undefined,
    selectedSpecialist: undefined,
    selectedBookingDate: undefined,
    selectedBookingTime: undefined,
    selectedServices: [],
    selectedProducts: [],
    paymentOption: null,
    oldPaymentOption: null,
    paymentType: null,
    isValidPaymentType: false,
    step: EBookingTabState.TAB_MENU,
    isShowNoShowPolicy: false,
    isShowOrderSummary: false,
    isNoShowPolicyAccepted: false,
    selectedPromocode: undefined,
    tempStore: {
        selectedSpecialist: undefined,
        selectedBookingDate: undefined,
        selectedBookingTime: undefined,
        selectedServices: [],
        selectedProducts: [],
    },
};

interface IStateType {
    modifyingAppointmentId: number | undefined;
    isModifyingAppointment: boolean;
    isShowFinalizeBooking: boolean;
    selectedShopLocation: IShopLocation | undefined;
    selectedSpecialist: ISpecialist | undefined;
    selectedBookingDate: string | undefined;
    selectedBookingTime: string | undefined;
    selectedServices: ISpecialistService[];
    selectedProducts: any[];
    oldPaymentOption: "pay_now" | "pay_later" | null;
    paymentOption: "pay_now" | "pay_later" | null;
    paymentType: "cash" | "stripe" | "pos" | "loyalty" | "other" | null;
    isValidPaymentType: boolean;
    step: EBookingTabState;
    isShowNoShowPolicy: boolean;
    isShowOrderSummary: boolean;
    isNoShowPolicyAccepted: boolean;
    selectedPromocode: any | undefined;
    tempStore: {
        selectedSpecialist: ISpecialist | undefined;
        selectedBookingDate: string | undefined;
        selectedBookingTime: string | undefined;
        selectedServices: ISpecialistService[];
        selectedProducts: any[];
    };
}

export const bookingSlice = createSlice({
    name: "booking",
    initialState: initialState as IStateType,
    reducers: {
        setBookingInitialState: (state) => {
            Object.assign(state, initialState);
        },
        setModifyingAppointmentId: (state, { payload }) => {
            state.modifyingAppointmentId = payload;
        },
        setIsModifyingAppointment: (state, { payload }) => {
            state.isModifyingAppointment = payload;
        },
        setIsShowFinalizeBooking: (state, { payload }) => {
            state.isShowFinalizeBooking = payload;
        },
        setSelectedShopLocation: (state, { payload }) => {
            state.selectedShopLocation = payload;
        },
        setSelectedSpecialist: (state, { payload }) => {
            state.selectedSpecialist = payload;
        },
        setSelectedBookingDate: (state, { payload }) => {
            state.selectedBookingDate = payload;
        },
        setSelectedBookingTime: (state, { payload }) => {
            state.selectedBookingTime = payload;
        },
        setSelectedServices: (state, { payload }) => {
            state.selectedServices = payload;
        },
        setSelectedProducts: (state, { payload }) => {
            state.selectedProducts = payload;
        },
        setOldPaymentOption: (state, { payload }) => {
            state.oldPaymentOption = payload;
        },
        addProduct: (state, { payload }) => {
            state.selectedProducts = [...state.selectedProducts, payload];
        },
        removeProduct: (state, { payload }) => {
            state.selectedProducts = state.selectedProducts.filter((_, idx) => idx !== payload);
        },
        setStep: (state, { payload }) => {
            state.step = payload;
        },
        setTempStore: (state, { payload }) => {
            state.tempStore = { ...state.tempStore, ...payload };
        },
        setPaymentType: (state, { payload }) => {
            state.paymentType = payload;
        },
        setPaymentOption: (state, { payload }) => {
            state.paymentOption = payload;
        },
        setIsValidPaymentType: (state, { payload }) => {
            state.isValidPaymentType = payload;
        },
        setIsShowNoShowPolicy: (state, { payload }) => {
            state.isShowNoShowPolicy = payload;
        },
        setIsShowOrderSummary: (state, { payload }) => {
            state.isShowOrderSummary = payload;
        },
        setIsNoShowPolicyAccepted: (state, { payload }) => {
            state.isNoShowPolicyAccepted = payload;
        },
        setSelectedPromocode: (state, { payload }) => {
            state.selectedPromocode = payload;
        },
    },
});

export const {
    setModifyingAppointmentId,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
    setSelectedProducts,
    addProduct,
    removeProduct,
    setStep,
    setTempStore,
    setPaymentType,
    setPaymentOption,
    setOldPaymentOption,
    setIsValidPaymentType,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsNoShowPolicyAccepted,
    setBookingInitialState,
    setSelectedPromocode,
} = bookingSlice.actions;

export const getBookingInfo = (state: RootState) => state.Booking;
