import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import StepProgressBar from "src/components/Stepper";
import { useAppSelector } from "src/redux/hooks";
import { EBookingTabState } from "./bookAppointment.interface";
import { getBookingInfo } from "./Booking.slice";
import { currentShop } from "src/redux/reducers/common/Common.slice";

const Stepper: FC = () => {
    const shop = useAppSelector(currentShop);
    const bookingInfo = useAppSelector(getBookingInfo);
    const { t } = useTranslation();
    const stepContent: Record<EBookingTabState, { title: string; subtitle: React.ReactNode; step: number } | undefined> = {
        [EBookingTabState.TAB_MENU]: {
            step: 1,
            title: t("Create an appointment"),
            subtitle: (
                <>
                    {t("Book your next appointment at")} <span className="text-primaryAppointment">{shop.business_name}</span>
                </>
            ),
        },
        [EBookingTabState.PRODUCT_BOOKING]: {
            step: 2,
            title: t("Add a product to your order"),
            subtitle: t("This is optional and this product can only be picked up in store"),
        },
        [EBookingTabState.FINALIZE_BOOKING]: {
            step: 3,
            title: t("Finalize Your Booking"),
            subtitle: t("Complete payment to complete the order"),
        },
        [EBookingTabState.SELECT_SPECIALIST]: undefined,
        [EBookingTabState.SELECT_DATE_TIME]: undefined,
        [EBookingTabState.SELECT_SERVICE]: undefined,
    };
    const renderStepComponent = () => {
        const content = stepContent[bookingInfo.step];
        if (content) {
            return (
                <>
                    <div className="mb-3">
                        <h3 className="location-title-text">{content.title}</h3>
                        <p className="location-sub-text">{content.subtitle}</p>
                    </div>
                    <div className="w-[100%] self-center">
                        <StepProgressBar steps={3} currentStep={content.step} />
                    </div>
                </>
            );
        }

        return null;
    };

    return <>{renderStepComponent()}</>;
};

export default Stepper;
