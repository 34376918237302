import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { getBookingInfo } from "../../Booking.slice";
import { EBookingTabState } from "../../bookAppointment.interface";

const ProductBasket = () => {
    const { t } = useTranslation();
    const booking = useAppSelector(getBookingInfo);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const selectedProducts = booking.selectedProducts.reduce((acc, product) => {
            const productId = product.id;
            const variantId = product.selectedVariant.id;
            const price = product.selectedVariant.inventory.price;

            const key = `${productId}-${variantId}-${price}`;

            if (!acc[key]) {
                acc[key] = { ...product, quantity: 0 };
            }
            acc[key].quantity += 1;

            return acc;
        }, {});

        setProducts(Object.values(selectedProducts));
    }, [booking]);

    return (
        <div className="lg:mt-4 flex flex-col gap-3 mt-3">
            <h2 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium   text-textGrey tracking-[0.03rem]">{t("Products")}</h2>
            {products.map((product: any, index: number) => (
                <div className="flex justify-between items-center" key={`${product.id}-${index}`}>
                    <div className="flex gap-2 items-center w-1/2">
                        <div className="bg-dropdownBackgroundColor w-[25px] h-[25px] flex justify-center items-center rounded-secondary text-txtAppointmentColor ">
                            <div className="pt-[2px]">{product.quantity}</div>
                        </div>
                        <p className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium pt-[2px] truncate max-w-[75px] lg:max-w-[148px] tracking-[0.03rem]">
                            {product.name}
                        </p>
                        <div className="max-w-max tracking-[0.03rem]">
                            <span className="w-max ml-[4px] rounded-secondary text-[10px] lg:text-[12px] lg:font-extralight border h-[19px] px-1 lg:px-1 lg:py-1 items-center justify-center flex text-txtAppointmentColor border-borderColorSecondary">
                                <span className="pt-[2px] flex tracking-[0.03rem]">{`${product?.selectedVariant?.size} ${product?.unit_of_measure}`}</span>
                            </span>
                        </div>
                    </div>
                    <div className="flex gap-4 items-center w-1/2 justify-end text-txtAppointmentColor tracking-[0.03rem]">
                        {booking.step === EBookingTabState.FINALIZE_BOOKING && <div>{product.quantity} x </div>}
                        <span className="text-txtcolor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium w-1/3 text-right">
                            {product?.selectedVariant?.inventory.price && `$${product?.selectedVariant?.inventory.price}`}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductBasket;
