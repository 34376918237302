import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { useTranslation } from "react-i18next";
import { INSTAGRAM_URL } from "../../../../constants/common";

const Layout = ({ children, homeSettings }: PropsWithChildren<{ homeSettings: any }>) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <section className="flex flex-col">
            {homeSettings?.status && (
                <div className="layout-columns">
                    <div className="layout-home-column-media bg-cover bg-center h-screen" style={{ backgroundImage: homeSettings?.background_url ? `url(${homeSettings?.background_url})` : "" }}>
                        <div className=" h-full bg-gray-800 bg-opacity-50 flex flex-col w-full justify-center mx-auto">
                            <div className="text-center  max-w-[580px] flex flex-col mx-auto justify-center">
                                {homeSettings?.is_title && <h1 className="text-[50px] text-white mt-[36px] leading-[100%] font-normal font-semithin mb-3 text-center">{homeSettings?.title}</h1>}

                                {homeSettings?.is_subtitle && <p className="text-white text-xl px-4 mb-4 lg:px-18 text-center leading-[1.1]">{homeSettings?.subtitle}</p>}

                                {/* <p className="text-white my-4 text-2xl text-center">5.0 (247 Google Reviews)</p> */}
                                <div className="flex flex-col lg:flex-row justify-center gap-2 lg:gap-3 mt-3 px-5">
                                    {homeSettings?.is_button && (
                                        <button className="bg-primary text-white px-8 py-3 h-[42px] rounded-3xl uppercase" onClick={() => navigate(ROUTES.LOCATION)}>
                                            {homeSettings?.button}
                                        </button>
                                    )}

                                    {homeSettings?.is_instgram_button && (
                                        <a
                                            target="_blank"
                                            href={`${INSTAGRAM_URL}/${homeSettings?.instgram_button}`}
                                            className="border border-txtsecondary txtcolor text-white rounded-3xl font-normal px-5 py-3 h-[42px] text-center uppercase"
                                        >
                                            {t("follow us on instagram")}
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="layout-home-column-content">{children}</div>
                </div>
            )}
        </section>
    );
};

export default Layout;
