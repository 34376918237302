import { ISelectedTab } from "src/Interface";

export const navLinks: { name: string; tabName: ISelectedTab }[] = [
    { name: "ABOUT US", tabName: "about" },
    { name: "LOCATION", tabName: "location" },
    { name: "TEAM", tabName: "team" },
    { name: "SERVICES", tabName: "service" },
    { name: "ACADEMY", tabName: "academy" },
    { name: "PRODUCT", tabName: "product" },
    { name: "TESTIMONIALS", tabName: "testimonial" },
    { name: "GALLERY", tabName: "gallery" },
    { name: "(ENG / FRA)", tabName: "language" },
];

export const initAction = {
    completeProfile: false,
    profile: false,
};
export interface IInitAction {
    completeProfile: boolean;
    profile: boolean;
}
