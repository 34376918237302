import React from "react";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";
import moment from "moment";
import { onError } from "src/utils/global-functions";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";

interface IProps {
    booking: IAppointmentBooking;
    handleAction: (action: string, booking: IAppointmentBooking) => (event: any) => void;
}
const BookingCard = ({ booking, handleAction }: IProps) => {
    const { t } = useTranslation();

    const textColorClass = booking.status === "completed" ? "text-[#2dce89]" : booking.status === "canceled" ? "text-[#E33B3B]" : "text-[#FDB022]";

    return (
        <div className="p-3 border rounded-secondary border-borderColorPrimary mt-1.5">
            <div className="flex items-start justify-between">
                <div className="flex flex-row">
                    <img src={booking.location.location_image_url} alt="" onError={onError} className="mr-4 w-10 h-10 object-cover rounded-primary" />
                    <div className="items-center">
                        <h3 className="text-sm font-medium text-txtAppointmentColor leading-[18.2px] pt-[2px]">{booking.location.name}</h3>
                        <p className="text-xs text-textGrey font-normal leading-[18px]">
                            {`${booking.location.street}, ${booking.location.city}, ${booking.location.country} ${booking.location.postal_code}`}
                        </p>
                    </div>
                </div>
                {booking.status === "completed" && (
                    <button className="text-xs text-primaryAppointment font-medium text-right flex-1" onClick={handleAction("tip", booking)}>
                        {t("Add Tip")}
                    </button>
                )}
            </div>
            <div className="mt-2 flex gap-2">
                <span className="text-xs flex items-center gap-1">
                    <BsCheckCircleFill className={`w-3 h-3 ${textColorClass}`} />
                    <span className={`font-medium ${textColorClass} pt-[2px] capitalize`}>{booking.status}</span>
                </span>
                <span className="text-xs text-txtAppointmentColor font-normal gap-1.5 flex items-center">
                    <span className="text-[#98a7c3] w-[6px] h-[6px] rounded-full bg-[#98a7c3] font-bold"></span>
                    <span>{moment(`${booking.booking_date} ${booking.booking_start_time}`).format("DD MMM YYYY · hh:mm A")}</span>
                </span>
            </div>
        </div>
    );
};

export default BookingCard;
